import React from 'react';
import config from '../config';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const AppHelmetProvider = (props) =>
  <HelmetProvider>
      <Helmet>
      <title>{config.description || 'REI Forms Live Onboarding'}</title>
      <link rel="icon" href={`${config.favicon || 'favicon-rei.ico'}`} />
      <meta
        name="description"
        content={config.description || 'REI Forms Live Onboarding'}
      />
    </Helmet>
    {props.children}
  </HelmetProvider>

export default AppHelmetProvider;
