/* eslint-disable */

export function init ($) {
  var rsvg = /^data:image/;

  $.widget("dynamicmethods.documentpane", {

    options: {
      id: 0,
      multi: true,
      prefix: 'documentpane',
      debug: false,
      scale: 1,
      config: null,
      fields: {},
      data: {},
      dirty: {},
      page: 0,
      print: false,
      fit: 'width',
      watermark: '',
      watermarkColor: false,
      readonly: [],
      prescale: false,
      locked: false,
      timestamps: {},
      photos: [],
      mappingMode: false,
      fontSizeMin: 15,
      finalised: false,
      signFields: [],
      variables: {},
      photoPage: false,
      annexureTags: [],
      writeonly: []
    },

    _tabIndexCounter: 1,

    _getTabIndex: function() {
      return this._tabIndexCounter++;
    },

    _create: function() {
      var options = this.options;

      this.createScratch();

      if(!options.print) {
        $(window).bind('resize', $.proxy(this.delayResize, this));
        this.createKeyHandler();
        this._datepickers();
      } else {
        $(this.element).addClass('print-documentpane');
        options.multi = true;
        options.fit = 'custom';
        options.scale = 1;
      }

      if(options.schema && (options.schema !== null)) {
        this.config(options.schema);
        this.render();
      }
    },

    delayResize: function() {
      if(this.resizeId) {
        clearTimeout(this.resizeId);
      }
      this.resizeId = setTimeout($.proxy(this.resize, this), 150);
    },

    createScratch: function() {
      var $scratchWidth = $('<div id="scratchWidth" style="border: 0; padding: 0; position: fixed; z-index: -1; display: block; white-space: pre; visibility: hidden;"></div>');
      var $scratchHeight = $('<div id="scratchHeight" style="border: 0; padding: 0; position: fixed; z-index: -1; display: block; white-space: pre-wrap; word-wrap: break-word; visibility: hidden;"></div>');

      this.scratchWidth = $scratchWidth[0];
      this.scratchHeight = $scratchHeight[0];
      $('body').append($scratchWidth,$scratchHeight);
    },

    createKeyHandler: function() {
      this.element.on('keydown', '.doc-textbox', $.proxy(this._onKeyDown, this));
      this.element.on('keypress', '.doc-textbox', $.proxy(this._onKeyPress, this));
      this.element.on('paste', '.doc-textbox', $.proxy(this._onPaste, this));

      $(document).keydown($.proxy(this._captureShortcuts, this));
      this.element.keydown($.proxy(this._captureShortcuts, this));
    },

    _captureShortcuts: function(e) {
      if ((String.fromCharCode(e.which).toLowerCase() == 's' && e.ctrlKey) || (e.which == 19)) {
        this._trigger('save');
        e.preventDefault();
        return false;
      }

      if ((String.fromCharCode(e.which).toLowerCase() == 'p' && e.ctrlKey)) {
        this._trigger('print');
        e.preventDefault();
        return false;
      }

      return true;
    },

    measureTextWidth: function(charc,key,field,fontSize) {
      var $scratch = $(this.scratchWidth);
      var $field = $(field);
      var config = $field.data('config');
      var value = $field.val();
      fontSize = fontSize || parseInt($field.css('font-size'),10);

      var caret = $field[0].selectionStart;
      var caretEnd = $field[0].selectionEnd;

      value = value.substring(0,caret) +
        String.fromCharCode(charc) +
        value.substring(caretEnd,value.length);

      $scratch
      .css({
        'font-family': $field.css('font-family'),
        'font-size': fontSize + 'px',
        'line-height': $field.css('line-height'),
        'height': $field.height(),
        'letter-spacing': $field.css('letter-spacing'),
        'box-sizing': $field.css('box-sizing'),
        'text-transform': $field.css('text-transform'),
        'padding': $field.css('padding')
      }).text('W');

      var max_char_width = $scratch[0].offsetWidth;
      $scratch.text(value);

      var swidth = $scratch.width();
      var fwidth = $field.width();

      if(config.fixedFont) {
        fwidth += (parseInt(Math.floor(fontSize*0.75),10)*2);
      }

      return {
        overflow: (swidth > fwidth),
        height: $scratch[0].offsetHeight,
        width: $scratch[0].offsetWidth,
        max: max_char_width
      };
    },

    measureTextHeight: function(charc,key,field) {

      var $scratch = $(this.scratchHeight);
      var $field = $(field);
      var value = $field.val();
      var caret = $field[0].selectionStart;
      var caretEnd = $field[0].selectionEnd;

      if (key === 13){
         value = value.substring(0,caret) +
              "\nNEWLINE" +
              value.substring(caretEnd,value.length);
      }else{
         value = value.substring(0,caret) +
              String.fromCharCode(charc) +
              value.substring(caretEnd,value.length);
      }

      $scratch
      .css({
        'font-family': $field.css('font-family'),
        'font-size': $field.css('font-size'),
        'line-height': $field.css('line-height'),
        'width': parseInt($field.width(),10) - 10
      })
      .text(value);

      return {
        overflow:$scratch.height() > $field.height(),
        height: $scratch[0].offsetHeight,
        width: $scratch[0].offsetWidth
      };
    },

    _setOption: function(key, value) {
      switch(key) {
        case 'multi':
          this.options.multi = value;
          this.refresh();
          break;
        default:
          break;
      }
    },

    _id: function() {
      return this.options.id++;
    },

    parseData: function(data) {
      $.extend(this.options.fields, data);
      this.options.data = null;
    },

    parseTimestamps: function(data) {
      var keys = Object.keys(data);
      for(var i=0; i<keys.length; i++) {
        var key = keys[i];
        if(/^dm_timestamp_/.test(key))
          this.options.timestamps[key] = parseInt(data[key], 10);
      }
    },

    setData: function(data) {
      $.extend(this.options.fields, data);
      $.extend(this.options.dirty, data);
      this.loadData(data);
    },

    updateAttachments: function (attachments) {
      this.options.attachments = attachments;
      this.refresh();
    },

    lockSigned: function(fields) {
      $('.doc-initial, .doc-signature').each(function(i, item) {
        var $item = $(item);
        var $name = $item.attr('data-name');

        if(fields.indexOf($name) > -1)
          $item.addClass('doc-signed');
      });
    },

    unsetSigned: function(fields, stamping) {
      for (var i = 0; i < fields.length; i++) {
        var field = fields[i];

        if (this.options.fields.hasOwnProperty(field)) {
          delete this.options.fields[field];
        }

        if (this.options.dirty.hasOwnProperty(field)) {
          delete this.options.fields[field];
        }
      }

      $('.doc-initial, .doc-signature').each(function(i, item) {
        var $item = $(item);
        var $name = $item.attr('data-name');

        if(fields.indexOf($name) > -1) {
          $item.css({
            'background-image': '',
            'background-repeat': '',
            'background-position': '',
            'background-size': '',
            'border': ''
          });
          if (!stamping) {
            $item.css({
              'display': 'none'
            });
          }
        }
      });
    },

    setTimestamp: function(name, value) {
      var data = [];
      var timestamp = value
        ? value.unix()
        : value;

      this.element.trigger(name, [value]);
      this.options.timestamps[name] = timestamp;
      data[name] = timestamp;

      $.extend(this.options.fields, data);
      $.extend(this.options.dirty, data);
    },

    setSigned: function(data) {
      $.extend(this.options.fields, data);
      $.extend(this.options.dirty, data);

      $('.doc-initial, .doc-signature').each(function(i, item) {
        var $item = $(item);
        var $name = $item.attr('data-name');

        if(data.hasOwnProperty($name)) {

          if(!rsvg.test(data[$name]))
            return $item.css({
              'background-image': '',
              'background-repeat': '',
              'background-position': '',
              'background-size': ''
            });

          $item.css({
            'background-image': 'url(' + data[$name] + ')',
            'background-repeat': 'no-repeat',
            'background-position': 'center',
            'background-size': 'contain',
            'border': 'none',
            'display': 'block'
          });
        }
      });

      $('.doc-textbox').each(function(i, item) {
        var $item = $(item);
        var $name = $item.attr('name');

        if(data.hasOwnProperty($name)) {
          if(!rsvg.test(data[$name])) {
            $item.val(data[$name]);
          }
        }
      });
    },

    loadData: function(data) {
      $('.doc-textbox, .doc-combobox, .doc-datefield').each($.proxy(function(i, item) {
        var $item = $(item);
        var name = $item.attr('name');
        var comboBox = $item.hasClass('doc-combobox');
        var autosize = $item.hasClass('doc-autosize');

        if(data.hasOwnProperty(name)) {
          $item.val(data[name]);
          if(comboBox && data[name] === 'BLANK') {
            $item.val('');
          }
          if(autosize)
            this.autoAdjustSize(false, false, $item);
        }
      }, this));

      $('.doc-strikebox').each(function(i, item) {
        var $wrapper = $(item);
        var $item = $wrapper.children().children().first();

        var isAlt = $wrapper.hasClass('doc-strikebox-alt');
        var name = $item.attr('name');
        var hasData = data.hasOwnProperty(name);
        var val = $item.val();
        var valMatch = (hasData && data[name] == val);

        if(isAlt) {
          if(valMatch) {
            $item.removeAttr('checked');
            $wrapper.removeClass('doc-checked');
          } else {
            $item.attr('checked', 'checked');
            $wrapper.addClass('doc-checked');
          }
        } else {
          if(!valMatch) {
            $item.removeAttr('checked');
            $wrapper.removeClass('doc-checked');
          }
        }

        $item.trigger('change', true);
      });

      $('.doc-checkbox').each(function(i, item) {
        var $wrapper = $(item);
        var $item = $wrapper.children().children().first();

        if ($item.val() == data[$item.attr('name')]) {
          $item.attr('checked', 'checked');
        }

        $item.trigger('change', true);
      });

      $('.doc-initial, .doc-signature').each(function(i, item) {
        var $item = $(item);
        var $name = $item.attr('data-name');

        if(data.hasOwnProperty($name)) {
          $item.addClass('doc-signed');
          $item.css({
            'background-image': 'url(' + data[$name] + ')',
            'background-repeat': 'no-repeat',
            'background-position': 'center',
            'background-size': 'contain',
            'border': 'none',
            'display': 'block'
          });
        }

      });

    },

    page: function(value) {
      if (this.options.multi)
        return;

      if (value === undefined) {
        return this.options.page;
      } else {
        if(this.options.page !== value) {
            this.options.page = value;
            this.refresh();
        }
      }
    },

    pages: function() {
      var schemaLength = this.options.schema.pages.length;
      var annexureLength = 0;

      if(this.options.annexures && this.options.annexures.length) {
        for(var i=0; i<this.options.annexures.length; i++) {
          annexureLength += this.options.annexures[i].schema.pages.length;
        }
      }

      return schemaLength + annexureLength;
    },

    refresh: function() {
      this.clear();
      this.render();
      this.resize();
    },

    clear: function() {
      $(this.element)
        .find('.doc-page')
        .remove();

      this.options.scale = 1;
    },

    config: function(value) {
      if(value === undefined) {
        var config = $.extend(true, {}, this.options.schema);
        $.each(config.pages, function(p, page) {
          $.each(page.content, function(i, item) {
            if (item.id) delete item.id;
          });

          $.each(page.background, function(i, item) {
            if (item.id) delete item.id;
          });

          $.each(page.form, function(i, item) {
            if (item.id) delete item.id;
          });
        });
        return config;
      } else {
        this.options.schema = value;
        this.options.page = 0;
        this.options.id = 0;
        this.options.strikeouts = value.strikeouts;
        this.options.photoPage = value.photoPage || false;

        if(this.options.hide) {
          this._hide(this.options.hide);
        }

        // if(this.options.watermark) {
          // this._watermark(this.options.watermark, this.options.watermarkColor);
        // }
      }
    },

    scale: function(value) {
      if(value === undefined) {
        return this.options.scale;
      } else {
        this.options.scale = value;
      }
    },

    mappingMode: function(value) {
      this.options.mappingMode = value;
    },

    dirty: function(clear) {
      if (clear === undefined) {
        return this.options.dirty;
      } else {
        this.options.dirty = {};
      }
    },

    fit: function(value) {
      if (value === undefined) {
        return this.options.fit;
      } else {
        this.options.fit = value;

        switch (value) {
          case 'width':
            this.scale(($(window).width() - this.element.offset().left - 40)/this.options.schema.defaults.pageWidth);
            break;

          case 'height':
            this.scale(($(window).height() - this.element.offset().top - 40)/this.options.schema.defaults.pageHeight);
            break;

          default:
            break;
        }
      }
    },

    _pageNumbering: function(options) {

      this.element.find('.doc-numbering').remove();

      var scale = this.options.scale;
      var pre_scale = this.options.prescale ? scale : 1;
      var defaults = this.options.schema.defaults;

      var content = this.element
        .find('.doc-page, .print-page')
        .not('.no-page-numbering')
        .children('.doc-content');

      var pages = content.length;
      var self = this;

      content.each(function(index, item) {
        var page = index+1;

        var opts = {
          html: 'Page ' + page + ' of ' + pages,
          numbering: true
        };

        for (var attr in options)
          opts[attr] = options[attr];

        self['_text']($(item), opts, defaults, pre_scale);
      });
    },

    resize: function(event) {
      if(!this.options.schema || (this.options.schema === null)) {
        return;
      }

      this.fit(this.options.fit);

      var scale = this.options.scale;
      var config = this.options.schema;
      var print = this.options.print;

      var width = parseInt(config.defaults.pageWidth*scale, 10);
      var height = parseInt(config.defaults.pageHeight*scale, 10);

      $('.doc-page').css({
        'width': width,
        'height': height
      });

      if(this.options.prescale) {
        $('.doc-content').css({
          'width': parseInt(config.defaults.pageWidth*scale, 10),
          'height': parseInt(config.defaults.pageHeight*scale, 10)
        });

        this.render();
      } else {
        var transformOrigin = '0 0';
        var transform = 'scale(' + scale.toFixed(3) + ', ' + scale.toFixed(3) + ')';

        $('.doc-content').css({
          '-webkit-transform-origin': transformOrigin,
          '-webkit-transform': transform,
          '-ms-transform-origin': transformOrigin,
          '-ms-transform': transform,
          '-moz-transform-origin': transformOrigin,
          '-moz-transform': transform,
          '-o-transform-origin': transformOrigin,
          '-o-transform': transform,
          'transform-origin': transformOrigin,
          'transform': transform
        });
      }
    },

    renderSection: function(p, page, defaults, annexure) {
      var self = this;
      var pages = this.pages();
      var scale = this.options.scale;
      var print = this.options.print;
      var config = this.options.schema;
      var pre_scale = this.options.prescale ? scale : 1;
      var cls = (print ? 'print-page' : 'doc-page') + ' doc-page-' + p;
      var width = (page.width === undefined) ? defaults.pageWidth : page.width;
      var height = (page.height === undefined) ? defaults.pageHeight : page.height;
      var noPageNumbering = ((page.hasOwnProperty('pageNumbering') && !page.pageNumbering) || !defaults.pageNumbering);
      var landscape = width>height;
      var annexureTags = this.options.annexureTags || [];

      if (this.options.finalised) {
        cls += ' doc-final';
      }

      if (annexure) {
        cls += ' doc-annexure';
      }

      var content = $(document.createElement('div')).addClass('doc-content');
      var section = $(document.createElement('form')).addClass(cls).css({
        width: parseInt(scale*width, 10) + 'px',
        height: parseInt(scale*height, 10) + 'px'
      });

      if(noPageNumbering) section.addClass('no-page-numbering');
      if(landscape && print) section.addClass('landscape-print-page');

      var defaultFontSize = false;
      var defaultLineHeight = false;

      if(defaults.fontSize) defaultFontSize = parseInt(defaults.fontSize, 10);
      if(defaults.lineHeight) defaultLineHeight = parseInt(defaults.lineHeight, 10);

      if(self.options.prescale) {
        content.css({
          'width': parseInt(width*scale, 10) + 'px',
          'height': parseInt(height*scale, 10) + 'px',
          'font-family': defaults.fontFamily
        });
      } else {
        var transformOrigin = '0 0';
        var transform = 'scale(' + scale.toFixed(3) + ', ' + scale.toFixed(3) + ')';

        content.css({
          'width': width + 'px',
          'height': height + 'px',
          'font-family': defaults.fontFamily,
          '-webkit-transform-origin': transformOrigin,
          '-webkit-transform': transform,
          '-ms-transform-origin': transformOrigin,
          '-ms-transform': transform,
          '-moz-transform-origin': transformOrigin,
          '-moz-transform': transform,
          '-o-transform-origin': transformOrigin,
          '-o-transform': transform,
          'transform-origin': transformOrigin,
          'transform': transform
        });
      }

      if(defaultFontSize) content.css('font-size', defaultFontSize + 'px');
      if(defaultLineHeight) content.css('line-height', defaultLineHeight + 'px');

      var watermark = this.getWatermark();
      if (watermark) this._text(content, watermark, defaults, pre_scale);

      $.each(page.background, function(i, item) {
        if(item.hasOwnProperty('templateTag') && !~annexureTags.indexOf(item.templateTag)) return;

        self['_' + (item.type ? item.type : 'rect')](content, item, defaults, pre_scale);
      });

      $.each(page.content, function(i, item) {
        if(item.hasOwnProperty('templateTag') && !~annexureTags.indexOf(item.templateTag)) return;

        self['_' + (item.type ? item.type : 'text')](content, item, defaults, pre_scale);
      });

      $.each(page.form, $.proxy(function(i, item) {
        if(item.hasOwnProperty('templateTag') && !~annexureTags.indexOf(item.templateTag)) return;

        var ua = navigator.userAgent;
        if(!self.options.print && item.type === 'combobox' && (ua.match(/iPad/i) || ua.match(/X11/i) || ua.match(/Android/i) || ua.match(/iPhone/i))) {
          self._comboboxMobile(content, item, defaults, pre_scale);
        } else {
          self['_' + (item.type ? item.type : 'textfield')](content, item, defaults, pre_scale);
        }
      },this));

      section.append(content);

      return section;
    },

    render: function() {
      var self = this;
      var pane = $(this.element);
      var config = this.options.schema;
      var annexures = this.options.annexures;
      var attachments = this.options.attachments;
      var scale = this.options.scale;
      var print = this.options.print;
      var pre_scale = this.options.prescale ? scale : 1;

      var writeOnly = (this.options.writeonly && this.options.writeonly.length)
        ? this.options.writeonly
        : [];

      var preAnnexures = annexures.filter(function (a) {
        return a.index < 0;
      });

      var postAnnexures = annexures.filter(function (a) {
        return a.index >= 0;
      });

      if(this.options.prescale) pane.empty();

      if (this.options.multi) {
        var pageCount=0;
        if(preAnnexures && preAnnexures.length) this.renderAnnexures(preAnnexures);

        $.each(config.pages, function (p, page) {
          pane.append(self.renderSection(pageCount++, page, config.defaults));
        });

        if(postAnnexures && postAnnexures.length) this.renderAnnexures(postAnnexures);
        if (attachments && attachments.length && !print) this.renderAttachments(attachments);
      } else {
        var page = config.pages[this.options.page];
        pane.append(self.renderSection(this.options.page, page));
      }

      if(this.options.data)
        this.parseData(this.options.data);

      if(this.options.photos.length)
        this._photos();

      this.loadData(this.options.fields);
      this._required(this.options.required);

      if (!writeOnly.length) this._readonly(this.options.readonly);
      if (writeOnly.length) this._writeonly(writeOnly);
      
      if(config.defaults.pageNumbering)
        this._pageNumbering(config.defaults.pageNumbering);

      if(config.defaults.hideFieldLines)
        pane.addClass('hidefieldlines');

      this._trigger('rendered');

      var mappingClasses = '.doc-textbox, .doc-textarea, .doc-combobox, .doc-checkbox, .doc-strikebox';
      $(this.element).off('click', mappingClasses, $.proxy(this._monitorClick, this));
      $(this.element).on('click', mappingClasses, $.proxy(this._monitorClick, this));
    },

    _monitorClick: function(e) {
      if(this.options.mappingMode) {
        e.preventDefault();
        e.stopPropagation();

        var target = $(e.currentTarget);
        var name = target.attr('name');
        var value = false;
        var type = 'Text';

        if(target.hasClass('doc-checkbox') || target.hasClass('doc-strikebox')) {
          var actualTarget = target.find('input');
          name = actualTarget.attr('name');
          value = actualTarget.attr('value');
          type = target.hasClass('doc-checkbox') ? 'Checkbox' : 'Strikeout';
        }

        if(target.hasClass('doc-combobox')) {
          var options = target.siblings('.doc-combobox-dropdown').first().children('li');
          value = [];
          type = 'Combobox';

          for(var i=0; i<options.length; i++) {
            value.push($(options[i]).html());
          }
        }

        this._trigger('mapping', e, {name: name, value: value, type: type});
        return false;
      }
    },

    _photos: function() {
      var self = this;
      var pane = $(this.element);
      var config = this.options.schema;
      var scale = this.options.scale;
      var print = this.options.print;
      var pre_scale = this.options.prescale ? scale : 1;
      var annexures_count = this.options.annexures ? this.options.annexures.length : 0;
      var page_count = (config.pages.length + annexures_count);

      var photo_page = this.options.photoPage;
      var photo_attr = photo_page.photos || false;

      var iwidth = (photo_attr && photo_attr.width) || 800;
      var iheight = (photo_attr && photo_attr.height) || 800;
      var margin_left = (photo_attr && photo_attr.marginLeft) || 72;
      var margin_right = (photo_attr && photo_attr.marginRight) || 72;
      var margin_top = (photo_attr && photo_attr.marginTop) || 220;
      var margin_bottom = (photo_attr && photo_attr.marginBottom) || 78;

      var width = config.defaults.pageWidth;
      var height = config.defaults.pageHeight;
      var landscape = width>height;

      var cols = Math.floor((width-margin_left-margin_right)/iwidth);
      var rows = Math.floor((height-margin_top-margin_bottom)/iheight);

      var col_space = (width-margin_left-margin_right-(cols*iwidth))/(cols+1);
      var row_space = (height-margin_top-margin_bottom-(rows*iheight))/(rows+1);

      var max_per_page = cols * rows;

      var createPhotoPage = function(p, photos) {

        var cls = (print ? 'print-page' : 'doc-page') + ' doc-page-' + (page_count+p);
        var content = $(document.createElement('div')).addClass('doc-content');

        var section = $(document.createElement('form')).addClass(cls).css({
          width: parseInt(scale*width, 10) + 'px',
          height: parseInt(scale*height, 10) + 'px'
        });

        if(landscape && print) {
          section.addClass('landscape-print-page');
        }

        if(self.options.prescale) {
          content.css({
            'width': parseInt(width*pre_scale, 10) + 'px',
            'height': parseInt(height*pre_scale, 10) + 'px',
            'font-family': config.defaults.fontFamily,
            'font-size': config.defaults.fontSize + 'px'
          });
        } else {
          var transformOrigin = '0 0';
          var transform = 'scale(' + scale.toFixed(3) + ', ' + scale.toFixed(3) + ')';

          content.css({
            'width': width + 'px',
            'height': height + 'px',
            'font-family': config.defaults.fontFamily,
            'font-size': config.defaults.fontSize + 'px',
            '-webkit-transform-origin': transformOrigin,
            '-webkit-transform': transform,
            '-ms-transform-origin': transformOrigin,
            '-ms-transform': transform,
            '-moz-transform-origin': transformOrigin,
            '-moz-transform': transform,
            '-o-transform-origin': transformOrigin,
            '-o-transform': transform,
            'transform-origin': transformOrigin,
            'transform': transform
          });
        }
        
        if(photo_page) {
          $.each(photo_page.background, function(i, item) {
            self['_' + (item.type ? item.type : 'rect')](content, item, config.defaults, pre_scale);
          });

          $.each(photo_page.content, function(i, item) {
            self['_' + (item.type ? item.type : 'text')](content, item, config.defaults, pre_scale);
          });

          $.each(photo_page.form, $.proxy(function(i, item) {
            var ua = navigator.userAgent;
            if(!self.options.print && item.type === 'combobox' && (ua.match(/iPad/i) || ua.match(/X11/i) || ua.match(/Android/i) || ua.match(/iPhone/i))) {
              self._comboboxMobile(content, item, config.defaults, pre_scale);
            } else {
              self['_' + (item.type ? item.type : 'textfield')](content, item, config.defaults, pre_scale);
            }
          },this));
        }

        for(var i=0; i<photos.length; i++) {

          var photo = photos[i];
          photo.left = margin_left+col_space+((i%cols)*(iwidth+col_space));
          photo.top = margin_top+row_space+(Math.floor(i/cols)*(iheight+row_space));
          photo.width = iwidth;
          photo.height = iheight;
          photo.labelTop = photo.top + iheight + 10;

          self._photo(content, photo, config.defaults, pre_scale);
        }

        section.append(content);
        return section;
      };

      var sectionCount = 0;
      var sectionPhotos = [];
      for(var i=0; i<this.options.photos.length; i++) {
        sectionPhotos.push(this.options.photos[i]);
        if(sectionPhotos.length === max_per_page || i === (this.options.photos.length-1)) {
          pane.append(createPhotoPage(sectionCount, sectionPhotos));
          sectionCount++;
          sectionPhotos = [];
        }
      }
    },

    _photo: function (content, config, defaults, scale) {
      var img = $('<img />');
      var label = $('<label></label>');
      var itemScale = this.scale();
      var timestampLabel = $('<label></label>');

      if (!config.id) {
        var id = this._id();
        config.id = 'photo-item-' + id;
        config.labelId = 'photo-label-item-' + id;
        config.timestampLabelId = 'photo-timestamp-label-' + id;
      }

      label
        .attr('id', config.labelId)
        .html(config.description)
        .addClass('doc-layer-content doc-photo-label')
        .css({
          'top': parseInt(config.labelTop * itemScale, 10) + 'px',
          'left': parseInt(config.left * itemScale, 10) + 'px'
        });

      if (config.time_taken) {
        timestampLabel
          .attr('id', config.timestampLabelId)
          .html(config.time_taken)
          .addClass('doc-layer-content doc-photo-timestamp')
          .css({
            'top': parseInt((config.top + 10) * itemScale, 10) + 'px',
            'left': parseInt((config.left + 10) * itemScale, 10) + 'px'
          });
      }

      img
        .attr('id', config.id)
        .attr('src', config.url)
        .addClass('doc-layer-content doc-photo')
        .css({
          'top': parseInt(config.top * itemScale, 10) + 'px',
          'left': parseInt(config.left * itemScale, 10) + 'px'
        });

      if (config.width) {
        img.css('max-width', parseInt(config.width * itemScale, 10) + 'px');
      }

      if (config.height) {
        img.css('max-height', parseInt(config.height * itemScale, 10) + 'px');
      }

      var transform = false;
      var transformOrigin = false;

      if (this.options.prescale) {
        if (!transformOrigin) {
          transformOrigin = '0% 0%';
          img.css({
            '-webkit-transform-origin': transformOrigin,
            '-ms-transform-origin': transformOrigin,
            '-moz-transform-origin': transformOrigin,
            '-o-transform-origin': transformOrigin,
            'transform-origin': transformOrigin
          });
          label.css({
            '-webkit-transform-origin': transformOrigin,
            '-ms-transform-origin': transformOrigin,
            '-moz-transform-origin': transformOrigin,
            '-o-transform-origin': transformOrigin,
            'transform-origin': transformOrigin
          });
        }

        if (!transform) {
          transform = 'scale(' + scale.toFixed(3) + ', ' + scale.toFixed(3) + ')';
        } else {
          transform = 'scale(' + scale.toFixed(3) + ', ' + scale.toFixed(3) + ') ' + transform;
        }

        img.css({
          '-webkit-transform': transform,
          '-ms-transform': transform,
          '-moz-transform': transform,
          '-o-transform': transform,
          'transform': transform
        });

        label.css({
          '-webkit-transform': transform,
          '-ms-transform': transform,
          '-moz-transform': transform,
          '-o-transform': transform,
          'transform': transform
        });

        timestampLabel.css({
          '-webkit-transform': transform,
          '-ms-transform': transform,
          '-moz-transform': transform,
          '-o-transform': transform,
          'transform': transform
        });
      }

      $(img).data('config', config);
      $(label).data('config', config);
      $(timestampLabel).data('config', config);

      content
        .append(img)
        .append(label);

      if (config.time_taken) {
        content.append(timestampLabel);
      }
    },

    renderAnnexures: function (annexures) {
      var self = this;
      var pane = $(this.element);
      var pageCount = this.options.schema.pages.length;

      var appendAnnexures = [];

      $.each(annexures, function (a, annexure) {
        $.each(annexure.schema.pages, function (p, annexurePage) {
          var page = self.renderSection(pageCount++, annexurePage, annexure.schema.defaults, true);
          appendAnnexures.push(page);
        });
      });

      pane.append(appendAnnexures);
    },

    renderAttachments: function (attachments) {
      if (this.options.print) return;
      
      var self = this;
      var pane = $(this.element);
      var pageCount = this.options.schema.pages.length;

      var appendAttachments = [];
      
      $.each(attachments, function (a, attachment) {
        $.each(attachment.pages, function (p, attachmentPage) {
          var page = self.renderSection(pageCount++, attachmentPage, {}, true);
          appendAttachments.push(page);
        });
      });
      
      pane.append(appendAttachments);
    },

    enableSigning: function(fields) {
      $('.doc-initial, .doc-signature').each(function(i, item) {
        var $item = $(item);
        var $name = $item.attr('data-name');

        if(fields.indexOf($name) > -1) $item.css('display', 'block');
      });
    },

    disableSigning: function() {
      $('.doc-initial, .doc-signature').each(function(i, item) {
        var $item = $(item);

        if(!$item.hasClass('doc-signed'))
          $item.css('display', 'none');
      });
    },

    scrollTo: function(selector) {
      var container = $(this.element);
      var scroll = $(this.element).find(selector);
      container.scrollTop(scroll.offset().top - container.offset().top + container.scrollTop());
    },

    updateAnnexures: function(annexures, readonly, tags) {
      this.options.annexures = annexures;
      this.options.annexureTags = tags;

      if (readonly && readonly.length) {
        this.options.readonly = readonly;
      }

      this.refresh();
    },

    _monitorClick: function(e) {
      if(this.options.mappingMode) {
        e.preventDefault();
        e.stopPropagation();

        var target = $(e.currentTarget);
        var name = target.attr('name');
        var value = false;
        var type = 'Text';

        if(target.hasClass('doc-checkbox') || target.hasClass('doc-strikebox')) {
          var actualTarget = target.find('input');
          name = actualTarget.attr('name');
          value = actualTarget.val();
          type = target.hasClass('doc-checkbox') ? 'Checkbox' : 'Strikeout';
        }

        if(target.hasClass('doc-combobox')) {
          var options = target.siblings('.doc-combobox-dropdown').first().children('li');
          value = [];
          type = 'Combobox';

          for(var i=0; i<options.length; i++) {
            value.push($(options[i]).html());
          }
        }

        this._trigger('mapping', e, {name: name, value: value, type: type});
        return false;
      }
    },

    removeAnnexures: function () {
      $(this.element)
        .find('.doc-annexure')
        .remove();
    },

    _initial: function(content, config, defaults, scale) {

      var item = $('<div></div>');
      if(!config.id) config.id = 'form-item-' + this._id();

      item
        .addClass('doc-layer-form doc-initial')
        .css('position', 'absolute')
        .css('top', parseInt(config.top*scale,10) + 'px' || 0)
        .css('left', parseInt(config.left*scale,10) + 'px' || 0)
        .css('width', parseInt(config.width*scale,10) + 'px')
        .css('height', parseInt(config.height*scale,10) + 'px')
        .attr('data-name', config.name);

      if(this.options.finalised || !~this.options.signFields.indexOf(config.name)) {
        item.css('display', 'none');
      }

      $(item).data('config', config);
      $(item).bind('click', $.proxy(this._signClick, this));

      content.append(item);
    },

    _signClick: function(e) {
      var target = $(e.currentTarget);
      var config = target.data('config');

      if(!this.options.finalised) {
        return this._trigger('sign', e, config);
      }

      if(!target.hasClass('doc-signed')) {
        return this._trigger('sign', e, config);
      }
    },

    _signature: function(content, config, defaults, scale) {

      var item = $('<div></div>');
      if(!config.id) config.id = 'form-item-' + this._id();

      item
        .addClass('doc-layer-form doc-signature')
        .css('position', 'absolute')
        .css('top', parseInt(config.top*scale,10) + 'px' || 0)
        .css('left', parseInt(config.left*scale,10) + 'px' || 0)
        .css('width', parseInt(config.width*scale,10) + 'px')
        .css('height', parseInt(config.height*scale,10) + 'px')
        .attr('data-name', config.name);

      if(this.options.finalised || !~this.options.signFields.indexOf(config.name)) {
        item.css('display', 'none');
      }

      $(item).data('config', config);
      $(item).bind('click', $.proxy(this._signClick, this));

      content.append(item);
    },

    _variable: function(content, config, defaults, scale) {
      var variables = Object.keys(this.options.variables);

      if(!config.variable) return
      if(!~variables.indexOf(config.variable)) return;

      config.html = this.options.variables[config.variable];
      this._text(content, config, defaults, scale);
    },

    _text: function(content, config, defaults, scale) {
      var item = $('<p />').html(config.html);

      if(!config.id)
        item.id = 'content-item-' + this._id();


      var lineHeight = false;
      var fontSize = false;
      var alignment = false;
      var textTransform = false;
      var wrapText = false;

      if(config.fontSize)
        fontSize = parseInt(config.fontSize, 10) + 'px';

      if(config.lineHeight)
        lineHeight = parseInt(config.lineHeight, 10) + 'px';

      if(!lineHeight && fontSize)
        lineHeight = fontSize;

      if(config.hasOwnProperty('alignLeft'))
        alignment = 'left';

      if(config.hasOwnProperty('alignCenter'))
        alignment = 'center';

      if(config.hasOwnProperty('alignRight'))
        alignment = 'right';

      if(config.hasOwnProperty('alignJustify'))
        alignment = 'justify';

      if(config.hasOwnProperty('textTransform'))
        textTransform = config.textTransform;

      if(config.wrapText) {
        wrapText = true;
      }

      item
        .addClass('doc-layer-content doc-text' + ((config.width && !alignment) ? ' fulljustify' : ''))
        .css({
          'color': config.color || '',
          'font-weight': (config.fontWeight ? config.fontWeight : ''),
          'font-style': (config.fontStyle ? config.fontStyle : ''),
          'font-family': (config.fontFamily ? (config.fontFamily == defaults.fontFamily ? '': config.fontFamily) : ''),
          'top': parseInt(config.top*scale, 10) + 'px',
          'left': parseInt(config.left*scale, 10) + 'px',
          'letter-spacing': (config.width ? '' : (config.letterSpacing ? parseInt(config.letterSpacing, 10) + 'px' : '')),
          'word-spacing': (config.wordSpacing ? parseInt(config.wordSpacing, 10) + 'px' : 'normal'),
          'width': (config.width ? parseInt(config.width, 10) + 'px' : '')
        })
        .attr('id', config.id);

      if(fontSize)
        item.css('font-size', fontSize);

      if(lineHeight)
        item.css('line-height', lineHeight);

      if(alignment) {
        item.css('text-align', alignment);

        if(alignment === 'justify')
          item.css('white-space', 'normal');
      }

      if(textTransform)
        item.css('text-transform', textTransform);

      if(wrapText) {
        item.css({
          'white-space': 'normal',
          'word-wrap': 'break-word'
        });
      }

      var transform = false;
      var transformOrigin = false;

      if(config.rotate > 0 || config.rotate < 0) {
        transformOrigin = config.rotateOrigin ? config.rotateOrigin : '0% 100%';
        transform = 'rotate(' + config.rotate + 'deg)';

        item.css({
          '-webkit-transform-origin': transformOrigin,
          '-webkit-transform': transform,
          '-ms-transform-origin': transformOrigin,
          '-ms-transform': transform,
          '-moz-transform-origin': transformOrigin,
          '-moz-transform': transform,
          '-o-transform-origin': transformOrigin,
          '-o-transform': transform,
          'transform-origin': transformOrigin,
          'transform': transform
        });
      }

      if(this.options.prescale) {

        if(!transformOrigin) {
          transformOrigin = '0% 0%';
          item.css({
            '-webkit-transform-origin': transformOrigin,
            '-ms-transform-origin': transformOrigin,
            '-moz-transform-origin': transformOrigin,
            '-o-transform-origin': transformOrigin,
            'transform-origin': transformOrigin
          });
        }

        if(!transform) {
          transform = 'scale(' + scale.toFixed(3) + ', ' + scale.toFixed(3) + ')';
        } else {
          transform = 'scale(' + scale.toFixed(3) + ', ' + scale.toFixed(3) + ') ' + transform;
        }

        item.css({
          'font-size': (config.fontSize ? parseInt(config.fontSize,10) + 'px' : ''),
          'letter-spacing': (config.width ? '' : (config.letterSpacing ? parseInt(config.letterSpacing,10) + 'px' : '')),
          'word-spacing': (config.wordSpacing ? parseInt(config.wordSpacing,10) + 'px' : 'normal'),
          '-webkit-transform': transform,
          '-ms-transform': transform,
          '-moz-transform': transform,
          '-o-transform': transform,
          'transform': transform
        });
      }

      $(item).data('config', config);
      if(config.numbering) item.addClass('doc-numbering');

      content.append(item);
    },

    _rect: function(content, config, defaults, scale) {
      var item = $('<div></div>');

      if(!config.id) {
          config.id = 'bg-item-' + this._id();
        }

        item
          .addClass('doc-layer-bg doc-rect')
          .css('position', 'absolute')
          .css('display', 'block')
          .css('border-color', config.strokeColor || '#949599')
          .css('border-style', 'solid')
          .css('background-color', config.color || 'transparent');

        item
          .css('top', parseInt(config.top*scale,10) + 'px' || 0)
          .css('left', parseInt(config.left*scale,10) + 'px' || 0)
          .css('border-width', parseInt(config.strokeWidth,10) + 'px' || '1px')
          .css('width', parseInt(config.width*scale,10) + 'px')
          .css('height', parseInt(config.height*scale,10) + 'px');

      $(item).data('config', config);

      content.append(item);
    },

    _line: function(content, config, defaults, scale) {
      var item = $('<div></div>');

      if(!config.id) {
          config.id = 'bg-item-' + this._id();
      }

      item
        .attr('id', config.id)
        .addClass('doc-layer-bg doc-line')
        .css({
          'background-color': config.color || '#949599'
        });

      item
        .css({
          'top': parseInt(config.top*scale,10) + 'px',
          'left': parseInt(config.left*scale,10) + 'px',
          'width': parseInt(config.width*scale,10) + 'px',
          'height': parseInt(config.height*scale,10) + 'px'
        });

      var transformOrigin = (config.rotate ? '0% 100%' : '');
      var transform = (config.rotate ? 'rotate(' + config.rotate + 'deg)' : '');

      if(transformOrigin && transform) {
        item.css({
          '-webkit-transform-origin': transformOrigin,
          '-webkit-transform': transform,
          '-ms-transform-origin': transformOrigin,
          '-ms-transform': transform,
          '-moz-transform-origin': transformOrigin,
          '-moz-transform': transform,
          '-o-transform-origin': transformOrigin,
          '-o-transform': transform,
          'transform-origin': transformOrigin,
          'transform': transform
        });
      }

      $(item).data('config', config);

      content.append(item);
    },

    _image: function(content, config, defaults, scale) {
      var item = $('<img />');

      if(!config.id) config.id = 'bg-item-' + this._id();

      var transformOrigin = '0 0';
      var transform = 'scale(' + scale.toFixed(3) + ', ' + scale.toFixed(3) + ')';

      item
        .attr({
          'id': config.id,
          'src': config.url})
        .addClass('doc-layer-bg doc-image')
        .css({
          'top': parseInt(config.top*scale, 10) + 'px',
          'left': parseInt(config.left*scale, 10) + 'px'
        })
        .css({
          '-webkit-transform-origin': transformOrigin,
          '-webkit-transform': transform,
          '-ms-transform-origin': transformOrigin,
          '-ms-transform': transform,
          '-moz-transform-origin': transformOrigin,
          '-moz-transform': transform,
          '-o-transform-origin': transformOrigin,
          '-o-transform': transform,
          'transform-origin': transformOrigin,
          'transform': transform
        });


      if(config.maxWidth) item.css('max-width', parseInt(config.maxWidth,10) + 'px');
      if(config.maxHeight) item.css('max-height', parseInt(config.maxHeight,10) + 'px');
      $(item).data('config', config);
      content.append(item);
    },

    _qrcode: function(content, config, defaults, scale) {
      if(!this.options.qrcode) return;

      config.url = this.options.qrcode;
      config.width = 1600;
      config.height = 1600;
      this._image(content, config, defaults, scale);
    },
  
    _logo: function(content, config, defaults, scale) {
      if(!this.options.logo) return;
      config.url = this.options.logo;
      this._image(content, config, defaults, scale);
    },

    _datefield: function(content, config, defaults, scale) {
      var item = $('<input type="text" />');
      var transformOrigin = (config.rotate ? '0% 100%' : '');
      var transform = (config.rotate ? 'rotate(' + config.rotate + 'deg)' : '');
      //var fontSize = (config.height) ? ((parseInt(config.height,10) >= 40) ? 32 : 22) : 32;

      if(!config.id)
        config.id = 'form-item-' + this._id();

      var ua = navigator.userAgent;
      if(ua.match(/iPad/i) || ua.match(/X11/i) || ua.match(/Android/i) || ua.match(/iPhone/i))
        item.attr('readonly', 'readonly');

      var fieldHeight = (config.height) ? parseInt(config.height,10) : defaults.height;
      var fontSize = (fieldHeight>=40) ? fieldHeight-10 : fieldHeight-4;
      if(config.fontSize) fontSize = config.fontSize;

      var lineHeight = fontSize;

      var textfieldStyle = config.fieldBorderStyle
        ? config.fieldBorderStyle
        : defaults.textfieldBorderStyle;

      if(textfieldStyle)
        item.addClass('doc-field-border-' + textfieldStyle);

      var textCase = config.textCase
        ? config.textCase
        : defaults.textfieldCase;

      if(textCase) item.addClass('doc-field-case-' + textCase);

      item
        .attr({
          'name': config.name,
          'id': config.id,
          'tabindex': this._getTabIndex()
        })
        .addClass('doc-layer-form doc-textbox doc-datefield')
        .css({
          'top': parseInt(config.top*scale, 10) + 'px',
          'left': parseInt(config.left*scale, 10) + 'px',
          'width': parseInt(config.width*scale, 10) + 'px',
          'height': parseInt(fieldHeight*scale, 10) + 'px',
          'font-size': parseInt(fontSize*scale, 10) + 'px',
          'line-height': parseInt(lineHeight*scale, 10) + 'px',
          '-webkit-transform-origin': transformOrigin,
          '-webkit-transform': transform,
          '-ms-transform-origin': transformOrigin,
          '-ms-transform': transform,
          '-moz-transform-origin': transformOrigin,
          '-moz-transform': transform,
          '-o-transform-origin': transformOrigin,
          '-o-transform': transform,
          'transform-origin': transformOrigin,
          'transform': transform,
          'background-color': this.options.print ? 'transparent !important': ''
        });

      if(config.height && parseInt(config.height,10) < 40) item.addClass('doc-textbox-medium');
      if(config.fontFamily) item.css('font-family', config.fontFamily);
      if(config.fontColor) item.css('color', config.fontColor);
      if(config.letterSpacing) item.css('letter-spacing', config.letterSpacing + 'px');
      if(config.wordSpacing) item.css('word-spacing', config.wordSpacing + 'px');
      if(config.fontWeight) item.css('font-weight', config.fontWeight);
      if(config.textTransform) item.css('text-transform', config.textTransform);
      if(config.hasOwnProperty('alignRight')) item.css('text-align', 'right');

      $(item).data('config', config);

      if(!this.options.print && !this.options.locked) {
        item.bind('change', $.proxy(this._onValueChange, this));
        item.on('drop', function() {
          setTimeout(function() {
            item.trigger('change');
          }, 1);
        });
      }

      var family = !!config.dateFamily;
      var timestampName = family
        ? 'dm_timestamp_family_' + config.dateFamily
        : 'dm_timestamp_name_' + config.name;

      this.element.on(timestampName, function(e, value) {
        var val = value
          ? value.format(config.format)
          : '';

        $(item)
          .val(val)
          .trigger('change');
      });

      if(this.options.locked || config.locked) {
        item.addClass('doc-readonly').attr('disabled', 'disabled');
      }

      content.append(item);
    },

    _textfield: function(content, config, defaults, scale) {
      var item = $('<input type="text" />');
      var transformOrigin = (config.rotate ? '0% 100%' : '');
      var transform = (config.rotate ? 'rotate(' + config.rotate + 'deg)' : '');

      if(!config.id) {
          config.id = 'form-item-' + this._id();
      }

      var fieldHeight = (config.height) ? parseInt(config.height,10) : defaults.height;

      var fontSize = (fieldHeight>=40) ? fieldHeight-10 : fieldHeight-4;
      if (fontSize > 50) fontSize = 50;
      if (config.fontSize) fontSize = config.fontSize;

      var lineHeight = fontSize;

      item
        .attr({
          'name': config.name,
          'id': config.id,
          'tabindex': this._getTabIndex(),
          'autocomplete': 'off'
        })
        .addClass('doc-layer-form doc-textbox');

      if(config.fixedFont) {
        item
          .addClass('doc-fixed-font')
          .css({
            'letter-spacing': parseInt(Math.floor(fontSize*1.7)*scale,10),
            'padding-left': parseInt(Math.floor(fontSize*0.75)*scale,10)
          });

        item.on('blur', function() {
          item.scrollLeft(0);
        });
      }

      if(config.scaleFontSize) {
        item.addClass('doc-autosize');
      }

      var textfieldStyle = config.fieldBorderStyle
        ? config.fieldBorderStyle
        : defaults.textfieldBorderStyle;

      if(textfieldStyle)
        item.addClass('doc-field-border-' + textfieldStyle);

      var textCase = config.textCase
        ? config.textCase
        : defaults.textfieldCase;

      if(textCase) item.addClass('doc-field-case-' + textCase);

      if(this.options.prescale) {

        transformOrigin = '0 0';
        transform = 'scale(' + scale.toFixed(3) + ', ' + scale.toFixed(3) + ')';
        item
          .css({
            'top': parseInt(config.top*scale, 10) + 'px',
            'left': parseInt(config.left*scale, 10) + 'px',
            'width': parseInt(config.width, 10) + 'px',
            'height': parseInt(fieldHeight, 10) + 'px',
            'font-size': parseInt(fontSize, 10) + 'px',
            'line-height': parseInt(lineHeight, 10) + 'px',
            '-webkit-transform-origin': transformOrigin,
            '-webkit-transform': transform,
            '-ms-transform-origin': transformOrigin,
            '-ms-transform': transform,
            '-moz-transform-origin': transformOrigin,
            '-moz-transform': transform,
            '-o-transform-origin': transformOrigin,
            '-o-transform': transform,
            'transform-origin': transformOrigin,
            'transform': transform,
            'background-color': this.options.print ? 'transparent !important': ''
          });
      } else {
        item
          .css({
            'top': parseInt(config.top*scale, 10) + 'px',
            'left': parseInt(config.left*scale, 10) + 'px',
            'width': parseInt(config.width*scale, 10) + 'px',
            'height': parseInt(fieldHeight*scale, 10) + 'px',
            'font-size': parseInt(fontSize*scale, 10) + 'px',
            'line-height': parseInt(lineHeight*scale, 10) + 'px',
            '-webkit-transform-origin': transformOrigin,
            '-webkit-transform': transform,
            '-ms-transform-origin': transformOrigin,
            '-ms-transform': transform,
            '-moz-transform-origin': transformOrigin,
            '-moz-transform': transform,
            '-o-transform-origin': transformOrigin,
            '-o-transform': transform,
            'transform-origin': transformOrigin,
            'transform': transform,
            'background-color': this.options.print ? 'transparent !important': ''
          });
      }

      if(config.height && parseInt(config.height,10) < 40) item.addClass('doc-textbox-medium');
      if(config.fontFamily) item.css('font-family', config.fontFamily);
      if(config.fontColor) item.css('color', config.fontColor);
      if(config.letterSpacing) item.css('letter-spacing', config.letterSpacing + 'px');
      if(config.wordSpacing) item.css('word-spacing', config.wordSpacing + 'px');
      if(config.fontWeight) item.css('font-weight', config.fontWeight);
      if(config.textTransform) item.css('text-transform', config.textTransform);
      if(config.hasOwnProperty('alignRight')) item.css('text-align', 'right');

      config.calulcatedFontSize = fontSize;
      $(item).data('config', config);

      if(!this.options.print && !this.options.locked && !config.locked) {
        item.bind('change', $.proxy(this._onValueChange, this));
        item.on('drop', function() {
          setTimeout(function() {
            item.trigger('change');
          }, 1);
        });
      }

      if(this.options.locked || config.locked) {
        item.addClass('doc-readonly').attr('disabled', 'disabled');
      }

      content.append(item);
    },

    _textarea: function(content, config, defaults, scale) {
      var self = this;
      var item = $('<textarea />');
      var fontSize = 30;
      var lineHeight = 34;

      config.id = 'form-item-' + self._id();

      item
        .attr({
            'name': config.name,
            'id': config.id,
            'tabindex': this._getTabIndex()
        })
        .addClass('doc-layer-form doc-textbox doc-textarea');


      var textareaStyle = config.fieldBorderStyle
        ? config.fieldBorderStyle
        : defaults.textareaBorderStyle;

      if(textareaStyle)
        item.addClass('doc-field-border-' + textareaStyle);

      var textCase = config.textCase
        ? config.textCase
        : defaults.textareaCase;

      if(textCase) item.addClass('doc-field-case-' + textCase);

      if(this.options.prescale) {

        var transformOrigin = '0 0';
        var transform = 'scale(' + scale.toFixed(3) + ', ' + scale.toFixed(3) + ')';

        item.css({
          'top': parseInt(config.top*scale,10) + 'px',
          'left': parseInt(config.left*scale,10) + 'px',
          'width': parseInt(config.width,10) + 'px',
          'height': parseInt(config.height,10) + 'px',
          'background-color': this.options.print ? 'transparent !important': '',
          '-webkit-transform-origin': transformOrigin,
          '-webkit-transform': transform,
          '-ms-transform-origin': transformOrigin,
          '-ms-transform': transform,
          '-moz-transform-origin': transformOrigin,
          '-moz-transform': transform,
          '-o-transform-origin': transformOrigin,
          '-o-transform': transform,
          'transform-origin': transformOrigin,
          'transform': transform
        });

      } else {
        item.css({
          'font-size': (scale == 1 ? '' : parseInt(fontSize*scale,10) + 'px'),
          'line-height': (scale ==1 ? '' : parseInt(lineHeight*scale,10) + 'px'),
          'top': parseInt(config.top*scale,10) + 'px',
          'left': parseInt(config.left*scale,10) + 'px',
          'width': parseInt(config.width*scale,10) + 'px',
          'height': parseInt(config.height*scale,10) + 'px',
          'background-color': this.options.print ? 'transparent !important': ''
        });
      }


      $(item).data('config', config);

      if(!this.options.print && !this.options.locked) {
        item.bind('change', $.proxy(this._onValueChange, this));
        item.on('drop', function() {
          setTimeout(function() {
            item.trigger('change');
          }, 1);
        });
      }

      if(this.options.locked) {
        item.addClass('doc-readonly').attr('disabled', 'disabled');
      }

      content.append(item);
    },

    _strikeout: function() {
      this._checkbox.apply(this, arguments);
    },

    _checkbox: function(content, config, defaults, scale) {
      var self = this;
      var item = $('<input type="checkbox" />');
      var wrapper = $('<span></span>');
      var innerWrapper = $('<span></span>');
      var parents = this.options.schema.defaults.parentCheckboxes || [];

      var isStrike = (config.height <= 10);
      var isParentOrChild = (parents.length && parents.indexOf(config.name) > -1);
      var isAlt = (isStrike && config.alt && config.alt === true);

      if(isParentOrChild && !isStrike && this.options.print)
        return;

      if(!config.id)
        config.id = 'form-item-' + this._id();

      var tabindex = this._getTabIndex();

      item
        .attr({'id': config.id, 'name': config.name})
        .prop('value', config.checkedValue);

      innerWrapper
        .attr({'tabindex': tabindex})
        .data('tabindex', tabindex)
        .addClass('doc-checkbox-wrapper');

      var top = isAlt && (config.rotate > 0)
        ? config.top - 20
        : config.top;

      wrapper
      .addClass('doc-layer-form doc-checkbox')
      .css({
          'top': parseInt(top*scale, 10) + 'px',
          'left': parseInt(config.left*scale, 10) + 'px',
          'width': parseInt(config.width*scale, 10) + 'px',
          'height': parseInt(config.height*scale, 10) + 'px'
      });

      if(isAlt)
        wrapper
          .addClass('doc-strikebox-alt');

      if(this.options.prescale) {
        wrapper.css({
          'background-size': 100*scale + '%',
          '-moz-background-size': 100*scale + '%'
        });
      }

      if(isStrike) {
        innerWrapper
        .removeClass('doc-checkbox-wrapper')
        .addClass('doc-strikebox-wrapper');

        wrapper
          .removeClass('doc-checkbox')
          .addClass('doc-strikebox');

        if(isParentOrChild)
          wrapper.addClass('doc-strikebox-child');

        item.attr('checked', 'checked');
      }

      if(config.rotate) {
        var transformOrigin = '0% 100%';
        var transform = 'rotate(' + config.rotate + 'deg)';

        wrapper
          .css({
            '-webkit-transform-origin': transformOrigin,
            '-webkit-transform': transform,
            '-ms-transform-origin': transformOrigin,
            '-ms-transform': transform,
            '-moz-transform-origin': transformOrigin,
            '-moz-transform': transform,
            '-o-transform-origin': transformOrigin,
            '-o-transform': transform,
            'transform-origin': transformOrigin,
            'transform': transform
          });
      }

      if(this.options.print) wrapper.addClass('doc-checkbox-print');
      $(item).data('config', config);

      if(!isAlt) item.bind('change', $.proxy(this._onCheckboxChange, this));

      if(!this.options.locked && !isAlt) {
        wrapper.bind('click', $.proxy(this._checkboxWrapperClick, this));
        wrapper.bind('keydown', $.proxy(this._checkboxWrapperKeyEvent, this));
      }

      if(this.options.locked) {
        innerWrapper.addClass('doc-readonly');
        item.attr('disabled', 'disabled');
      }

      wrapper.append(innerWrapper);
      wrapper.children('span').append(item);
      content.append(wrapper);
    },

    _comboboxMobile: function(content, config, defaults, scale) {
      var self = this;
      var item = $('<select></select>');

      config.items = config.items || [];

      config.items.forEach(function(option) {
        var opt = (option === 'BLANK') ? '' : option;
        item.append($('<option>' + opt + '</option>'));
      });

      if(!config.id) {
          config.id = 'form-item-' + this._id();
      }

      item
      .attr({
        'id': config.id,
        'name': config.name
      })
      .addClass('doc-layer-form doc-combobox')
      .css({
        'top': parseInt(config.top*scale,10) + 'px',
        'left': parseInt(config.left*scale,10) + 'px',
        'width': parseInt(config.width*scale,10) + 'px',
        'height': parseInt(config.height*scale,10) + 'px'
      });

      if(this.options.print) item.addClass('doc-checkbox-print');
      if(config.height <= 10) item.addClass('doc-strikebox');

      $(item).data('config', config);

      if(!this.options.locked) {
        item.bind('change', $.proxy(this._onValueChange, this));
      }

      if(this.options.locked) {
        item.addClass('doc-readonly');
        item.attr('disabled', 'disabled');
      }

      content.append(item);
    },

    _combobox: function(content, config, defaults, scale) {
      var self = this;
      var item = $('<input type="text">');
      var options  = $('<ul></ul>');
      var fontSize = 30;
      var lineHeight = 34;

      config.items = config.items || [];

      config.items.forEach(function(option, index) {
        var opt = (option === 'BLANK') ? '&nbsp;' : option;
        options.append($('<li>' + opt + '</li>'));
      });

      if(!config.id) {
        config.id = 'form-item-' + this._id();
      }

      item.css({
        'font-size': (scale == 1 ? '' : parseInt(fontSize*scale,10) + 'px'),
        'line-height': (scale ==1 ? '' : parseInt(lineHeight*scale,10) + 'px')
      });

      options.css({
        'font-size': (scale == 1 ? '' : parseInt(fontSize*scale,10) + 'px'),
        'line-height': (scale ==1 ? '' : parseInt(lineHeight*scale,10) + 'px')
      });

      item
      .attr({
        'id': config.id,
        'name': config.name,
        'tabindex': this._getTabIndex()
      })
      .addClass('doc-layer-form doc-combobox')
      .css({
        'top': parseInt(config.top*scale,10) + 'px',
        'left': parseInt(config.left*scale,10) + 'px',
        'width': parseInt(config.width*scale,10) + 'px',
        'height': parseInt(config.height*scale,10) + 'px',
        'background-color': this.options.print ? 'transparent !important' : ''
      });

      options
      .addClass('doc-combobox-dropdown doc-hide')
      .css({
        'top': parseInt((config.top+config.height+1)*scale,10) + 'px',
        'left': parseInt(config.left*scale,10) + 'px',
        'min-width': parseInt((config.width-1)*scale,10) + 'px'
      });

      if(!this.options.locked) {
        item.bind('keydown', function(f) {
          if(f.keyCode == 9) return;
          if(f.keyCode == 13) {
            var clicked = options.children('.selected').html();
            if(clicked === '&nbsp;') clicked = '';
            item.val(clicked);
            item.trigger('change');
            hideMenu();
          }

          f.preventDefault();

          if(f.keyCode === 38 || f.keyCode === 40) {

            showMenu();
            var children = options.children();
            var cur = children.filter('.selected');

            var sel;
            if(cur.length === 0) {
              sel = (f.keyCode === 38) ? children.last() : children.first();
            } else {
              cur.removeClass('selected');
              sel = (f.keyCode === 38) ? cur.prev() : cur.next();
            }
            var selVal = sel.html();
            if(selVal === '&nbsp;') selVal = '';

            sel.addClass('selected');
            item.val(selVal);
            item.trigger('change');
          }

          return false;
        });

        var blockHide = false;
        var forceHide = function(){
          blockHide = false;
          hideMenu();
        };

        var showMenu = function(e) {
          if(!options.hasClass('doc-hide')) return;
          options.removeClass('doc-hide');
          $(window).one('mouseup', forceHide);
        };

        var hideMenu = function(e) {
          if(blockHide) {
            blockHide = false;
            return;
          }
          $(window).off('mouseup', forceHide);
          options.addClass('doc-hide');
          //item.unbind('keydown');
        };

        item.bind('click focus mousedown', showMenu);
        item.bind('blur', hideMenu);

        options.children()
          .bind('mouseup', function(e) {
            var clicked = options.children('.selected').html();
            if(clicked === '&nbsp;') clicked = '';
            item.val(clicked);
            item.trigger('change');
          })
          .bind('mouseover', function(e) {
            options.children().removeClass('selected');
            $(e.currentTarget).addClass('selected');
          })
          .bind('mousedown', function(){
            blockHide = true;
          });

        $(item).data('config', config);
        item.bind('change', $.proxy(this._onValueChange, this));
      }

      if(this.options.locked) {
        item.addClass('doc-readonly');
        item.attr('disabled', 'disabled');
      }

      content.append(item);
      if(!this.options.print) content.append(options);
    },

    _checkboxWrapperClick: function(event) {
      var $target = $(event.currentTarget);
      var $check = $target.children().children().first();

      if($target.hasClass('doc-readonly')) return false;
      $target.toggleClass('doc-checked');
      $check.trigger('change');
    },

    _checkboxWrapperKeyEvent: function(event) {
      var $target = $(event.currentTarget);
      var $check = $target.children().children().first();

      if(/13|32/.test(event.keyCode)) {
        event.preventDefault();
        $target.toggleClass('doc-checked');
        $check.trigger('change');
        return false;
      }
    },

    _onCheckboxChange: function(event, forced) {
      if(!forced) {
        var $target = $(event.target);
        var id = $target.attr('id');
        var name = $target.attr('name');
        var value = $target.val();
        var isStrike = $target.parent().hasClass('doc-strikebox');
        var isChecked = $target.attr('checked');
        if(isChecked) {
          $target.removeAttr('checked');
          isChecked = false;
        } else {
          $target.attr('checked', 'checked');
          isChecked = true;
        }

        if(isStrike) {
          this._onStrikeoutChange(id, name, isChecked);
        } else {
          var sel = 'input[name="' + name + '"]';
          var not = '#' + id;
          var same = this.element.find(sel).not(not);

          same.each(function(i, item) {
            var $i = $(item);
            var $w = $i.parent().parent();
            var isAlt = $w.hasClass('doc-strikebox-alt');

            if(!isAlt) {
              if($i.val() === value && isChecked) {
                $i.attr('checked', 'checked');
              } else {
                $i.removeAttr('checked');
              }
            } else {
              if(isChecked) {
                $i.removeAttr('checked');
              } else {
                $i.attr('checked', 'checked');
              }
              $w.toggleClass('doc-checked');
            }

            $i.trigger('change', true);
          });
        }
        if(!this.options.locked) this._onValueChange(event);
      } else {
        var $check = $(event.target);
        var $span = $check.parent().parent();
  var isAlt = $span.hasClass('doc-strikebox-alt');

        if($span.hasClass('doc-strikebox')) {
    if(isAlt) {
            if($check.attr('checked')) {
              $span.removeClass('doc-checked');
            } else {
              $span.addClass('doc-checked');
            }
    } else {
            if($check.attr('checked')) {
              $span.addClass('doc-checked');
            } else {
              $span.removeClass('doc-checked');
            }
          }
          if(!this.options.locked) this._onValueChange(event);
        } else {
          if($check.attr('checked')) {
            $span.addClass('doc-checked');
          } else {
            $span.removeClass('doc-checked');
          }
        }
      }
    },

    _onStrikeoutChange: function(id, name, value) {
      var selector = '[name="' + name + '"]';
      var related = $(selector).not('#' + id);

      related.removeAttr('checked');
      if(value) related.attr('checked', 'checked');
      return related.trigger('change', true);
    },

    getWatermark: function () {
      if (!this.options.watermark) return false;

      var watermark = this.options.watermark;
      var fontSize = watermark.length < 6 ? 600 : 400;
      var pageWidth = this.options.schema.defaults.pageWidth;
      var pageHeight = this.options.schema.defaults.pageHeight;
      var positionScaleLeft = watermark.length < 6 ? 1 : 0.75;
      var positionScaleTop = watermark.length < 6 ? 1.75 : 2;

      return {
        type: 'text',
        html: this.options.watermark,
        fontSize: fontSize,
        left: (parseInt(pageWidth/3.5, 10))*positionScaleLeft,
        top: (parseInt(pageHeight/3, 10))*positionScaleTop,
        color: this.options.watermarkColor || '#F3F3F4',
        fontFamily: 'Liberation Sans',
        fontWeight: 'bold',
        rotate: -45,
        opacity: 0.5
      };
    },

    _watermark: function(watermark, color) {
      this.options.watermark = watermark;

      var fontSize = watermark.length < 6 ? 600 : 400;

      var pageWidth = this.options.schema.defaults.pageWidth;
      var pageHeight = this.options.schema.defaults.pageHeight;

      var positionScaleLeft = watermark.length < 6 ? 1 : 0.75;
      var positionScaleTop = watermark.length < 6 ? 1.75 : 2;

      this.options.watermarkConfig = {
        type: 'text',
        html: this.options.watermark,
        fontSize: fontSize,
        left: (parseInt(pageWidth/3.5, 10))*positionScaleLeft,
        top: (parseInt(pageHeight/3, 10))*positionScaleTop,
        color: color || '#F3F3F4',
        fontFamily: 'Liberation Sans',
        fontWeight: 'bold',
        rotate: -45,
        opacity: 0.5
      };

      for(var i=0; i<this.options.schema.pages.length; i++) {
        var page = this.options.schema.pages[i];
        page.background.splice(0,0,this.options.watermarkConfig);
      }

      if(this.options.annexures) {
        for(var i=0; i<this.options.annexures.length; i++) {
          for(var x=0; x<this.options.annexures[i].schema.pages.length; x++) {
            var page = this.options.annexures[i].schema.pages[x];
            page.background.splice(0,0,this.options.watermarkConfig);
          }
        }
      }
    },

    _required: function(required) {
      if(this.options.locked) return;

      if (required === undefined) {
        return this.options.required;
      } else {
        this.options.required = required;
        if (required) {
          $.each(required, $.proxy(function(i, item) {
            this.element.find('.doc-textbox[name="' + item + '"]').addClass('doc-required');
            this.element.find('.doc-checkbox[name="' + item + '"]').addClass('doc-required');
          }, this));
        } else {
          this.element.find('.doc-required').removeClass('doc-required');
        }
      }
    },

    _readonly: function(readonly) {
      if(readonly && $.isArray(readonly)) {
        $.each(readonly, $.proxy(function(i, item) {
          this.element
            .find('.doc-textbox[name="' + item + '"]')
            .addClass('doc-readonly')
            .attr('disabled', 'true');

          this.element
            .find('.doc-checkbox .doc-checkbox-wrapper > input[name="' + item + '"], .doc-strikebox .doc-strikebox-wrapper > input[name="' + item + '"]')
            .attr('disabled', 'true')
            .parent()
            .removeAttr('tabindex')
            .addClass('doc-readonly')
            .parent()
            .addClass('doc-readonly');
        }, this));
      }
    },

    _writeonly: function(writeonly) {
      this.element
        .find('.doc-textbox')
        .addClass('doc-readonly')
        .attr('disabled', 'true');

      this.element
        .find('.doc-checkbox .doc-checkbox-wrapper > input, .doc-strikebox .doc-strikebox-wrapper > input')
        .attr('disabled', 'true')
        .parent()
        .removeAttr('tabindex')
        .addClass('doc-readonly')
        .parent()
        .addClass('doc-readonly');

      $.each(writeonly, $.proxy(function(i, item) {
        var field = this.element.find('.doc-textbox[name="' + item + '"]');

        if (field) {
          field
            .removeClass('doc-readonly')
            .removeAttr('disabled');
        }

        var cb = this.element.find('.doc-checkbox .doc-checkbox-wrapper > input[name="' + item + '"], .doc-strikebox .doc-strikebox-wrapper > input[name="' + item + '"]');

        if (cb) {
          cb
          .removeAttr('disabled')
          .parent()
          .attr('tabindex', cb.parent().data('tabindex'))
          .removeClass('doc-readonly')
          .parent()
          .removeClass('doc-readonly');
        }
      }, this));
    },

    _datepickers: function() {
      var self = this;

      if(this.options.print) return;
      if(this.options.locked) return;

      this.element.on(
        'click',
        '.doc-datefield',
        $.proxy(this._displayDatePicker, this)
      );

      this.element.on(
        'keydown',
        '.doc-datefield',
        $.proxy(this._keyDownDatePicker, this)
      );
    },

    _keyDownDatePicker: function(e) {
      var allowed = [9, 16];
      var code = e.keyCode || e.charCode;

      if(allowed.indexOf(code) > -1)
        return;

      e.preventDefault();
      e.stopPropagation();
      this._displayDatePicker(e);
    },

    _displayDatePicker: function(e) {
      var input = $(e.target);
      var config = input.data('config');
      var family = !!config.dateFamily;

      input.blur();

      var name = family
        ? 'dm_timestamp_family_' + config.dateFamily
        : 'dm_timestamp_name_' + config.name;

      var currentValue = this.options.timestamps[name]
        ? this.options.timestamps[name]
        : false;

      this._trigger('date', e, {
        name: name,
        initialValue: currentValue
      });
    },

    _hide: function(hide) {
      var config = this.options.schema;
      config.pages.splice(0, hide);
    },

    _onPaste: function(event) {
      if(event.type=='paste') {
        var field = event.target;
        var value = field.value;
        if($(field).hasClass('doc-textarea')) {

          setTimeout($.proxy(function(){
            var data = this.measureTextHeight(null,null,field);
              var field_height = $(field).height();

              if(data.height >= field_height) {
              field.value = value;
              }
          },this),0);

        } else {

          setTimeout($.proxy(function() {
            var data = this.measureTextWidth(null,null,field);
            var field_width = $(field).width();

            if(data.width >= field_width - parseInt(data.max*0.5, 10)) {
              field.value = value;
            }
          }, this), 0);
        }
      }
    },

    _onKeyDown: function(e){
      if(e.keyCode !== 8) return true;
      this._onKeyPress(e);
    },

    _onKeyPress: function(event) {
      var field = event.target;
      var charc = event.charCode;
      var key = event.keyCode;
      var config = $(field).data('config');
      if(event.ctrlKey) return;

      if(key === 8 // backspace
        || key === 9 // escape
        || key === 27 // escape
        || key === 46 // delete
        || (key >= 16 && key <= 20)) {
        return;
      }

      if($(field).hasClass('doc-textarea')) {
        if(this.measureTextHeight(charc,key,field).overflow) {
          event.preventDefault();
        }
      } else {
        if(config.scaleFontSize)
          this.autoAdjustSize(charc,key,field);

        if(this.measureTextWidth(charc,key,field).overflow) {
          event.preventDefault();
        }
      }
    },


    autoAdjustSize: function(charc, key, field){
      var $field = $(field);
      var fontSize = parseInt($field.css('font-size'), 10);
      var config = $field.data('config');
      var originalFontSize = config.calulcatedFontSize;

      if(this.measureTextWidth(charc, key, field).overflow){
        if(fontSize === this.options.fontSizeMin) return;

        while(this.measureTextWidth(charc, key, field, fontSize).overflow){
          fontSize -= 1;
        }

        $field.css({
          'font-size': fontSize + 'px',
          'line-height': fontSize + 'px'
        });

      } else if(fontSize < originalFontSize) {

        while(!this.measureTextWidth(charc, key, field, fontSize).overflow
          && fontSize < originalFontSize + 1){
            fontSize += 1;
        }

        fontSize -= 1;
        $field.css({
          'font-size': fontSize + 'px',
          'line-height': fontSize + 'px'
        });
      }
    },

    _onValueChange: function(event) {
      var data = {};
      var target = $(event.target);
      var id = target.attr('id');
      var name = target.attr('name');
      var type = target.attr('type');
      var val = target.val();

      if(type === 'checkbox') {
        data[name] = (target.attr('checked') ? val : 'Off');
      } else {
        var replacer = target.hasClass('doc-textarea')
          ? /[^A-Za-z \u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]'`~\n\r]*/g
          : /[^A-Za-z \u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]'`~]*/g;

        val = val.replace(replacer, '');
        data[name] = val;

        if(!target.hasClass('doc-datefield')) {
          $('[name="' + name + '"]').not('#' + id).val(val);
        }
      }

      $.extend(this.options.fields, data);
      $.extend(this.options.dirty, data);

      this._trigger('values', event, { name: name, value: data[name] });
      this._trigger('updated');
    }
  });

};  

export default init;