import Moment from 'moment';
import { Dialog, Transition } from '@headlessui/react'
import React, { useMemo, Fragment }  from 'react';
import { DayPickerSingleDateController } from 'react-dates';

const Picker = ({ defaultDate, isOpen, onSubmit, onClose }) => {
  var date = useMemo(() => {
    return defaultDate 
      ? Moment.unix(defaultDate) 
      : Moment()
  }, [ defaultDate ]);


  const onDateChange = (date) => {
    onSubmit(date);
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-800 opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className='flex justify-center items-center'>
              <DayPickerSingleDateController
                focused
                date={date}
                numberOfMonths={1}
                transitionDuration={0}
                hideKeyboardShortcutsPanel
                onDateChange={onDateChange}
              />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
};

export default Picker;