
function init (platform) {
  switch (platform) {
    case 'NSW': return require('./lib/documentpane-nsw.js').default
    case 'WA': return require('./lib/documentpane-wa.js').default
    default: return require('./lib/documentpane.js').default
  }
};

export function styles (platform) {
  switch (platform) {
    case 'NSW': return require('./lib/documentpane-nsw-css.js').default
    case 'WA': return require('./lib/documentpane-wa-css.js').default
    default: return require('./lib/documentpane-css.js').default
  }
}

export default init;