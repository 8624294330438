import React from 'react';
import { 
  Switch, 
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
 
import { Provider, Consent, PartyRequest, Checkin } from './containers';
import { Nav } from './components';
import { MOCK_ENABLED } from './constants';

import useQueryParams from './hooks/useQueryParams';

function App () {
  const params = useQueryParams(MOCK_ENABLED);

  return (
    <div>
      <Nav platform={params.platform} />
      <Router>
        <Switch>
          <Route path="/contact-us">
            <Consent
              email={params.email}
              phone={params.phone}
              lastName={params.lastName}
              platform={params.platform} 
              firstName={params.firstName}
            />
          </Route>

          <Route path="/check-in">
            <Checkin 
              formId={params.formId} 
              platform={params.platform} 
            />
          </Route>

          <Route path="/request">
            <PartyRequest
              formId={params.formId}
              platform={params.platform}
              requestId={params.requestId}
            />
          </Route>

          <Route exact path="/">
            <Provider
              status={params.status}
              signerId={params.signerId}
              envelopeId={params.envelopeId}
            />
          </Route>
          
          <Route path='*'>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </div>    
  );
}

export default App;
