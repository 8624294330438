import formslive from './formslive';
import realworks from './realworks';
import base from './base';

export default {
  'BASE': base,
  'REALWORKS': realworks,
  'FORMS_LIVE': formslive
}

