import React from 'react';
import { useThemeState, defaultTheme } from '../context/theme';
import classNames from '../lib/class-names';
import chroma from 'chroma-js';

function Nav(props) {
  const theme = useThemeState();

  if (theme.loading) {
    return null;
  }

  function applyBorder () {
    const color = theme.colors['nav-background'] || theme.colors.primary;

    return (color && chroma(color).luminance() > 0.60)
      ? 'border-b border-gray-300 shadow-xs'
      : ''
  };

  return (
    <nav className={classNames("bg-nav-background", applyBorder())}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <img className="h-8 w-auto" src={theme.logo_url || defaultTheme.logo_url} alt={theme.name} />
            </div>
          </div>          
        </div>
      </div>
    </nav>
  );
}

export default Nav;
