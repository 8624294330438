import React from 'react';
import { BarLoader } from 'react-spinners';

function Loading() {
  return (
    <div className="container mx-auto flex justify-center mt-8">
      <BarLoader
        color={'rgba(30, 66, 159)'}
        width={100}
        height={4}
      />
    </div>
  );
}


export const Spinner = () =>
  <svg className="inline-flex text-white mr-2 absolute" style={{ left: '0.25rem' }} width="24" height="24" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
      <g fill="none" fillRule="evenodd" strokeWidth="2">
          <circle cx="22" cy="22" r="1">
              <animate attributeName="r"
                  begin="0s" dur="1.8s"
                  values="1; 20"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.165, 0.84, 0.44, 1"
                  repeatCount="indefinite" />
              <animate attributeName="stroke-opacity"
                  begin="0s" dur="1.8s"
                  values="1; 0"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.3, 0.61, 0.355, 1"
                  repeatCount="indefinite" />
          </circle>
          <circle cx="22" cy="22" r="1">
              <animate attributeName="r"
                  begin="-0.9s" dur="1.8s"
                  values="1; 20"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.165, 0.84, 0.44, 1"
                  repeatCount="indefinite" />
              <animate attributeName="stroke-opacity"
                  begin="-0.9s" dur="1.8s"
                  values="1; 0"
                  calcMode="spline"
                  keyTimes="0; 1"
                  keySplines="0.3, 0.61, 0.355, 1"
                  repeatCount="indefinite" />
          </circle>
      </g>
  </svg>

export default Loading;
