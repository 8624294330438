const UTILITY_STEPS = {
  INTRO: 'INTRO',
  DETAILS: 'DETAILS'
};

const MOCK_QUERY_STRING = {
  'formId': '1',
  'platform': 'qld',
  'status': 'complete',
  'signerId': 'test_signer',
  'envelopeId': '0000-0000-0000-0000-0000-0000-0001',

  'firstName': 'John',
  'lastName': 'Smith',
  'phone': '0888888888',
  'email': 'johnsmith@example.org'
};

const BASE_ENV = process.env.REACT_APP_ENV || 'development';
const SHOW_ERRORS = (process.env.REACT_APP_SHOW_ERRORS || 'false') === 'true';

const IS_DEV = BASE_ENV === 'development';
const DEFAULT_PROVIDER = IS_DEV && (process.env.REACT_APP_PROVIDER || 'movinghub');
const MOCK_ENABLED = false && (process.env.REACT_APP_MOCK_ENABLED || IS_DEV);

const MOCK_USER_DETAILS = {
  first_name: 'john',
  last_name: 'smith',
  contact: '0400000000',
  email: 'johnsmith@example.org'
};

export {
  IS_DEV,
  BASE_ENV,
  SHOW_ERRORS,
  MOCK_ENABLED,
  UTILITY_STEPS,
  DEFAULT_PROVIDER,
  MOCK_USER_DETAILS,
  MOCK_QUERY_STRING
};
