import React from 'react';

function Negative() {
  return (
    <>
      <p className="mt-4 text-center text-base leading-6 text-blue-600 font-semibold tracking-wide uppercase">Connect Utilities</p>
      <h3 className="text-center mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Not a problem
      </h3>
      <p className="px-6 py-2 mt-2 text-base leading-6 text-gray-500 text-center">
        If you change your mind and want us to help connect your utilities for free, please contact your agent.
      </p>
    </>
  );
}

export default Negative;
