import React, { useState } from 'react';

import { 
  Error,
  Loading,
  Positive,
  Negative,
  Redirect
} from '../components'

import useUtilities from '../hooks/useUtilities';
import { UTILITY_STEPS as STEPS } from '../constants';

function Utilities({ 
  data,
  Intro,
  Details,
  signerId,
  platform,
  providerId,
  envelopeId
}) {
  const [ handleSubmit, status, error ] = useUtilities({
    signerId: signerId, 
    envelopeId: envelopeId, 
    providerId: providerId
  });

  const [ step, setStep ] = useState(STEPS.INTRO);

  const onYesClick = () => !!(Details && data.details)
    ? setStep(STEPS.DETAILS)
    : handleSubmit(true)

  const onNoClick = () => 
    handleSubmit(false)

  const onDetailsSubmit = values =>
    handleSubmit(true, values);

  if (status.loading) {
    return <Loading />
  }

  if (error) {
    return (
      <Error message="Hey, something went wrong but don't worry we'll let the team know." />
    )
  }

  if (!status.complete) {
    switch (step) {
      case STEPS.INTRO: return <Intro onYesClick={onYesClick} onNoClick={onNoClick}></Intro>
      case STEPS.DETAILS: return <Details onSubmit={onDetailsSubmit} data={data.details} />
      default: return null
    }
  }

  if (status.termsAccepted) {
    return status.url 
      ? <Redirect url={status.url} /> 
      : <Positive />
  }
    
  return <Negative />
}


export default Utilities;
