import React from 'react';
import CompleteSvg from '../images/completed.svg';
import LaterSvg from '../images/viewed.svg';

function Complete({ status }) {
  let title = 'Hello!';
  let message = 'Usually someone arrives here from a DocuSign request, so check your email.';
  let img = CompleteSvg;
  let alt = 'Complete';

  switch (status) {
    case 'complete':
      title = 'You\'re All Done!';
      message = 'You\'ll receive a copy once everyone has signed';
      break;
    case 'later':
    case 'viewed':
      title = 'You haven\'t quite finished';
      message = 'You haven\'t yet signed your agreement. We\'ll remind you again later.';
      img = LaterSvg;
      alt = 'Later';
      break;
    default:
      break;
  }

  return (
    <>
      <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
        <div className="text-center">
          <h2 className="text-2xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-4xl sm:leading-none md:text-4xl">
            {title}
          </h2>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            {message}
          </p>          
        </div>
        <div>
          <img className="w-48 mx-auto mt-8" src={img} alt={alt} />
        </div>
      </div>
    </>
  );
}

export default Complete;
