export default `
  @media print {    
    @page {
      size: A4 portrait;
      margin: 0;
    }

    div {
      display:none;
    }

    #noprint {
      display:block !important;
      margin-top: 10%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }


  @media screen {

    body {
      font-weight: normal;
    }

    #content {
      overflow-y: hidden;
    }

    #noprint {
      display:none !important;
    }

    .ui-selectmenu {
      top: 50px !important;
    }

    .print-documentpane {
      border: 0;
      margin: 0;
      padding: 0;
    }

    section.doc-page {  
      margin-left: auto;
      margin-right: auto;
      border: 1px solid #DDD;
      background-color: white;
      position: relative;
      margin-top: 20px;
      overflow: hidden;
      color: #000;
    }

    section.print-page {
      overflow: hidden !important;
      border: 0;
      margin: 0;
      padding: 0;
      position: relative; 
      text-overflow: clip;
      page-break-after: always;
    }

    section.landscape-print-page {
      margin-top: -3px !important;
    }

    .doc-signature {
      background-color: rgba(244, 231, 55, 0.5);
      border: 1px solid #FFB238;
    }

    .doc-initial {
      background-color: rgba(244, 231, 55, 0.5);
      border: 1px solid #FFB238;
    }

    .doc-text {
      margin: 0;
      padding: 0;
      display: block;
      position: absolute;
      white-space: pre;
      cursor: default;
      text-shadow: none !important;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
    }

    .doc-rect {
      position: absolute;
      display: block;
      margin: 0;
      padding: 0;
      border-style: solid;
      border-width: 0;
    }

    .doc-line {
      margin: 0;
      padding: 0;
      position: absolute;
      display: block;
      border-style: none;
      border: 0px;
    }

    .doc-photo {
      margin: 0;
      padding: 0;
      position: absolute;
      display: block;
      border: 4px solid #000;
    }

    .doc-photo-label {
      position: absolute;
      margin: 0;
      padding: 0;
      font-size: 36px;
    }

    .doc-photo-timestamp {
      margin: 0;
      padding: 0;
      position: absolute;
      color: yellow;
      font-size: 90%;
      z-index: 10000;
    }

    .doc-image {
      position: absolute;
      display: block;
    }

    .doc-image-wrapper {
      width: 300px;
      height: 200px;
      position: relative;
    }

    .doc-image-wrapper > img {
      position:absolute; 
      bottom: 0;
      max-width: 100%;
    }

    .doc-textbox {
      color: #000;
      font-family: 'Liberation Sans';
      position: absolute;
      font-size: 32px;
      line-height: 36px;  
      background-color: rgba(221, 228, 255, 0.5);
      border: none;
      padding:0;
      overflow: hidden;
      resize: none;
    }

    .doc-textbox-medium {
      font-size: 22px;
      line-height: 26px;
    }

    .doc-textbox:focus {
      -webkit-transform: translateZ(0);
      background-color: transparent;
      border: none;
      outline: none;
    }

    .doc-checkbox {
      display: block;
      position: absolute;
      overflow: hidden;

      background-color: rgba(221, 228, 255, 0.5);
      border: 1px solid #949599;
    }

    .doc-checkbox > input {
      -moz-appearance: none;
      -webkit-appearance: none;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    .doc-checkbox.doc-checked {
      background: url(/img/forms/check.png) no-repeat center;
    }

    .doc-required {
      border:2px groove red;
    }

    .doc-strikebox.doc-checked {
      background-color: #000;
    }

    .doc-strikebox { 
      display: block;
      position: absolute;
      margin-top: 5px;
      border: 0;
      background-color: rgba(218, 1, 1, 0.2);
      cursor: pointer;
    }

    .doc-readonly {
      background-color: transparent;
      -webkit-text-fill-color:#000;
      color: #000;
    }

    .print-documentpane .doc-strikebox, .print-documentpane .doc-checkbox {
      background-color: transparent;
    }

    .print-documentpane .doc-strikebox.doc-checked {
      background-color: #000;
    }

    .doc-strikebox > input {
      display: none;
    }

    .doc-strikebox:checked {
      background-image: none;
      background-color: rgba(221, 228, 255, 0.5);
      border: 1px solid #949599;
      border: none;
    }

    .doc-checkbox-print:checked {
      background-image: none;
      background-color: transparent;
      border: none;
    }

    .fulljustify {
      text-align: justify !important;
      white-space: normal !important;
      text-justify: distribute;
    }

    .fulljustify:after {
      content: "";
      display: inline-block !important;
      width: 100%;
    }

    .doc-combobox {
      color: #000;
      position: absolute;
      font-family: 'liberationsans';
      font-size: 30px;
      line-height: 34px;
      background-color: rgba(221, 228, 255, 0.5);
      border: none;
      padding: 1px;
      overflow: hidden;
      cursor: default;
    }

    .doc-combobox-dropdown {
      position: absolute;
      font-size: 32px;
      line-height: 36px;
      border: none;
      overflow: hidden;
      cursor: default;

      list-style: none outside;
      background-color: #fff;
      z-index: 10000;
      margin: 0;
      padding: 0;

      border-style: solid;
      border-color: #000;
      border-width: 0px 1px 1px 1px;

      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
    }

    .doc-combobox-dropdown li {
      margin: 0;
      padding: 2px 5px;
      font-family: "liberationsans", Helvetica, Arial, sans-serif;
    }

    .doc-combobox-dropdown li.selected {
      background-color: #c6c7c8;
    }

    .doc-hide {
      display: none;
    }
  }
`;
