import { useState, useRef, useCallback, useEffect } from 'react';
import { useApplyTheme } from '../context/theme';

import config from '../config';

const usePartyRequest = (formId, requestId) => {
  const [ error, setError ] = useState(null);
  const [ request, setRequest ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const lastUpdated = useRef(null);

  useApplyTheme(request, 'agency.theme', loading);

  const fetchRequest = useCallback(async () => {
    setLoading(true);

    try {
      const response = await fetch(
        `${config.api}/requests/${formId}/${requestId}`,
        {  
          method: 'GET', 
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }
      );

      if (response.status === 404) {
        var error =  new Error('Request not found. Please contact the agent if further information is required.')
        error._friendly = true;

        throw error;
      }

      const data = await response.json();

      if (data.error) {
        return setError(data.error);
      }

      lastUpdated.current = new Date().getTime();
      setRequest(data);
    } catch (e) {
      setError((e._friendly && e.message) || 'An unknown error occurred fetching the request details');
    } finally {
      setLoading(false);
    }
  }, [ formId, requestId ])

  useEffect(() => {
    fetchRequest();
  }, [ fetchRequest ]);

  return [ loading, request, error, fetchRequest, lastUpdated.current ];
}

export const useSaveRequest = (formId, requestId, setNotification) => {
  const [ loading, setLoading ] = useState(false);

  const save = useCallback(async (values, completed) => {
    setLoading(true);

    try {
      const response = await fetch(
        `${config.api}/requests/${formId}/${requestId}`,
        { 
          body: JSON.stringify({ values, completed }),
          method: 'PUT', 
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }
      );

      setLoading(false);

      var data = await response.json(); //TODO handle data

      if (data.error) {
        return setNotification({
          success: false,
          message: data.error,
          title: 'An error occurred'
        });
      }

      return true;
    } catch (e) {
      setLoading(false);
      setNotification({
        success: false,
        message: 'An unknown error occurred updating the form data',
        title: 'An error occurred'
      });
      return false;
    }
  }, [ formId, requestId, setNotification ])

  return [ save, loading ];
}

export default usePartyRequest;