import React, { useState } from 'react';
import { geolocated } from 'react-geolocated';

import Img from '../images/verified.svg';

import Policy from '../components/policy';
import config from '../config';

function CheckinForm({ handleSubmit, handleChange, data, errors }) {
  const [accept, setAccept] = useState(false);
  const [error, setError] = useState(null);
  const [showPolicy, setShowPolicy] = useState(false);

  const isError = (name) => {
    return errors && errors.find((i) => (i.context.key === name));
  };
  const handleAccept = (event) => {
    setAccept(event.target.checked);
  };
  const preSubmit = (event) => {
    event.preventDefault();

    if (!accept) {
      return setError('Test');
    }

    return handleSubmit();
  }
  const handlePolicy = (event) => {
    event.preventDefault();
    setShowPolicy(true);
  }
  const closePolicy = (event) => {
    setShowPolicy(false);
  }

  return showPolicy ? 
  (<Policy onClose={closePolicy} />) :
  (
    <form onSubmit={preSubmit}>
      <p className="mt-4 text-center text-base leading-6 text-blue-600 font-semibold tracking-wide uppercase">Contact Tracing Check-In</p>
    <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Contact Details
        </h3>
        <p className="mt-1 text-sm text-justify leading-5 text-gray-500">
          We are collecting personal information in order to assist the State Government with COVID-19 contact tracing.
        </p>
        <p className="mt-2 text-sm text-justify leading-5 text-gray-500">
        The personal information provided by you will be securely stored and made available only to appropriately authorised officers of the Department of Health or Hospital and Health Services. Your personal information will not be disclosed to other third parties without consent, unless required by law.
        </p>
      </div>

      <div className="mt-4 grid grid-cols-1 row-gap-4 col-gap-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="first_name" className="block text-sm font-medium leading-5 text-gray-700">
              First name<span className="mt-2 font-semibold text-red-600">*</span>
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input id="first_name" name="first_name" value={data.first_name} onChange={handleChange} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="First Name" />
            </div>
            { isError('first_name') && (<p className="mt-2 text-xs font-semibold text-red-600" id="email-error">field required.</p>) }
          </div>
  
          <div className="sm:col-span-3">
            <label htmlFor="last_name" className="block text-sm font-medium leading-5 text-gray-700">
              Last name<span className="mt-2 font-semibold text-red-600">*</span>
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input id="last_name" name="last_name" value={data.last_name} onChange={handleChange} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="Last Name" />
            </div>
            { isError('last_name') && (<p className="mt-2 text-xs font-semibold text-red-600" id="email-error">field required.</p>) }
          </div>
  
          <div className="sm:col-span-3">
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
              Email address<span className="mt-2 font-semibold text-red-600">*</span>
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input id="email" name="email" type="email" value={data.email} onChange={handleChange} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="Email Address" />
            </div>
            { isError('email') && (<p className="mt-2 text-xs font-semibold text-red-600" id="email-error">field required.</p>) }
          </div>
  
          <div className="sm:col-span-3">
            <label htmlFor="mobile" className="block text-sm font-medium leading-5 text-gray-700">
              Mobile Phone<span className="mt-2 font-semibold text-red-600">*</span>
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input type="tel" id="mobile" name="mobile" value={data.mobile} onChange={handleChange} className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="Mobile Phone" />
            </div>
            { isError('mobile') && (<p className="mt-2 text-xs font-semibold text-red-600" id="email-error">field required.</p>) }
          </div>
        </div>

        <div className="relative flex items-start mt-4">
          <div className="flex items-center h-5">
            <input id="do_not_contact" name="do_not_contact" type="checkbox" checked={!data.do_not_contact} onChange={handleChange} className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out" />
          </div>
          <div className="ml-3 text-sm leading-5">
            <label htmlFor="do_not_contact" className="font-medium text-gray-700">I agree to the agency contacting me regarding this property for the purpose of providing a real estate service.</label>
          </div>
        </div>

        <div className="relative flex items-start mt-2">
          <div className="flex items-center h-5">
            <input id="privacy" checked={accept} onChange={handleAccept} type="checkbox" className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out" />
          </div>
          <div className="ml-3 text-sm leading-5">
            <label htmlFor="privacy" className="font-medium text-gray-700">I agree to the following <button onClick={handlePolicy} className="underline font-bold text-blue-600">privacy policy</button>.</label>
          </div>
        </div>

        { error && (
          <div className="rounded-md bg-red-50 p-4 mt-4" id="error_alert">
          <div className="flex">
            <div className="flex-shrink-0">          
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm leading-5 font-medium text-red-800">
              You must agree to the privacy policy before proceeding.
              </h3>              
            </div>
          </div>
        </div>

        )}

        <div className="mt-4 border-t border-gray-200 pt-5">
          <div className="flex justify-end">
            <span className="sm:max-w-sm w-full inline-flex rounded-md shadow-sm">
              <button type="submit" className="sm:max-w-sm w-full inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
                Check-in
              </button>
            </span>
          </div>
        </div>
      </div>
    </form>
  );  
}

function Success() {
  return (
    <>
      <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
        <div className="text-center">
          <h2 className="text-2xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-4xl sm:leading-none md:text-4xl">
            Thank you
          </h2>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Thank you for checking in.
          </p>          
        </div>
        <div>
          <img className="w-48 mx-auto mt-8" src={Img} alt="Thank you" />
        </div>
      </div>
    </>
  );
}

function Checkin({ formId, coords }) {
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    lat: '',
    long: '',
    do_not_contact: true
  });

  const [complete, setComplete] = useState(false);
  const [errors, setErrors] = useState(null);
  
  const handleChange = (event) => {    
    event.persist();

    var value = event.target.type === 'checkbox' ? !event.target.checked : event.target.value;

    setData(data => ({ ...data,
      [event.target.name]: value
    }));
  }

  const handleSubmit = (event) => {
    var ok = false;

    if (event) {
      event.preventDefault();
    }

    if (coords) {
      data.lat = coords.latitude + '';
      data.long = coords.longitude + '';
    }

    return fetch(
      config.api + '/check-in/' + formId,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    ).then((response) => {
      ok = response.ok;
      return response.text();
    }).then((body) => {
      if (ok) {
        return setComplete(true);
      } else {
        return setErrors(JSON.parse(body).errors);
      }
    }).catch((err) => {      
      console.log(err);
    });
  }

  return (
    complete ?
      (<Success />):
      (<CheckinForm handleSubmit={handleSubmit} handleChange={handleChange} data={data} errors={errors} />)
  );
}

export default geolocated({})(Checkin);
