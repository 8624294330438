import React from 'react';

import * as Providers from '../providers';
import { Error, Loading, Complete } from '../components';
import useOnbaord from '../hooks/useOnboard';
import { SHOW_ERRORS } from '../constants';

const Provider = ({ 
  status, 
  signerId,
  platform,
  envelopeId
}) => {
  const [ loading, onboard, error ] = useOnbaord(signerId, envelopeId, status, SHOW_ERRORS);

  const getProvider = () => {
    switch (onboard.provider) {
      case 'mmi': return Providers.MMI;
      case 'foxie': return Providers.Foxie;
      case 'home-now': return Providers.HomeNow;
      case 'dc': return Providers.DirectConnect;
      case 'movinghub': return Providers.MovingHub;
      case 'myconnect': return Providers.MyConnect;
      case 'prd-connect': return Providers.PRDConnect;
      case 'your-porter': return Providers.YourPorter;
      case 'whole-house': return Providers.WholeHouse;
      case 'best-connect': return Providers.BestConnect;
      case 'compare-connect': return Providers.CompareConnect;
      case 'default': return Complete;
      default: return Complete;
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error || !onboard) {
    return <Error message={error} />;
  }

  const Component = getProvider();

  return (
    <Component
      data={onboard}
      status={status}
      platform={platform}
      signerId={signerId}
      envelopeId={envelopeId}
    />
  );
}

export default Provider;
