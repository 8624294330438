export default `
@media print {
  @page {
    size: A4 portrait;
    margin: 0;
  }

  div {
    display:none;
  }

  #noprint {
    display:block !important;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}


@media screen {

  body {
    font-weight: normal;
  }

  #content {
    overflow-y: hidden;
  }

  #noprint {
    display:none !important;
  }

  .ui-selectmenu {
    top: 50px !important;
  }

  .print-documentpane {
    border: 0;
    margin: 0;
    padding: 0;
  }

	form.doc-page {
    line-height: normal;
		margin-left: auto;
		margin-right: auto;
		border: 1px solid #DDD;
		background-color: white;
		-moz-box-shadow: 0 0 10px 2px #1c1c1c;
		-webkit-box-shadow: 0 0 10px 2px #1c1c1c;
		box-shadow: 0 0 10px 2px #1c1c1c;
		position: relative;
		margin-top: 20px;
    color: #000;
  }

  form.print-page {
    overflow: hidden !important;
    border: 0;
    margin: 0;
    padding: 0;
    position: relative;
    text-overflow: clip;
    page-break-after: always;
  }

  form.landscape-print-page {
    margin-top: -3px !important;
  }
  
  .fixed-helper {
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 50;
  }

  .fixed-helper-center {
    top: 20px;
    width: 100%;
  }

  .fixed-helper .container {
    padding: 20px;
    border-radius: 0;
    background-color: #E1E1E1;
    width: 300px;
  }

  .fixed-helper-center .container {
    margin-left: auto;
    margin-right: auto;
    width: 500px;
  }

  .fixed-helper-center label {
     margin-right: 5px;
   }

  .fixed-helper h4 {
    font-size: 100%;
    font-weight: bold;
   }

  .fixed-helper p {
    font-size: 90%;
  }

  .fixed-helper input[type=number] {
    -webkit-appearnce: none;
    width: 50px;
  }

  .doc-signature {
    background-color: rgba(244, 231, 55, 0.5);
    border: 1px solid #FFB238;
  }

  .doc-initial {
    background-color: rgba(244, 231, 55, 0.5);
    border: 1px solid #FFB238;
  }

  .doc-optional {
    background-color: rgba(244, 130, 55, 0.5);
  }

  .optional-notice {
    color: #D34200;
  }

  .doc-text {
    margin: 0;
    padding: 0;
    display: block;
    position: absolute;
    white-space: pre;
    cursor: default;
    text-shadow: none !important;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .doc-rect {
    position: absolute;
    display: block;
    margin: 0;
    padding: 0;
    border-style: solid;
    border-width: 0;
  }

  .doc-line {
    margin: 0;
    padding: 0;
    position: absolute;
    display: block;
    border-style: none;
    border: 0;
  }

  .doc-photo {
    margin: 0;
    padding: 0;
    position: absolute;
    display: block;
    border: 4px solid #000;
  }

  .doc-photo-label {
    position: absolute;
    margin: 0;
    padding: 0;
    font-size: 36px;
  }

  .doc-photo-timestamp {
    margin: 0;
    padding: 0;
    position: absolute;
    color: yellow;
    font-size: 90%;
    z-index: 10000;
  }

  .doc-image {
    position: absolute;
    display: block;
  }

  .doc-textbox {
    color: #000;
    font-family: 'Arial';
    position: absolute;
    font-size: 30px;
    line-height: 32px;
    background-color: rgba(221, 228, 255, 0.5);
    border: none;
    padding:0;
    overflow: hidden;
    resize: none;
  }

  .doc-fixed-font {
    font-family: "Courier New", Courier, monospace;
    font-size: 50px;
    letter-spacing: 35px;
    padding-left: 13px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    line-height: 65px;
  }

  .doc-textbox-medium {
    font-size: 22px;
    line-height: 26px;
  }

  .doc-combobox {
    color: #000;
    position: absolute;
    font-family: 'Arial';
    font-size: 30px;
    line-height: 34px;
    background-color: rgba(221, 228, 255, 0.5);
    border: none;
    padding: 1px;
    overflow: hidden;
  }

  .doc-combobox-dropdown {
    position: absolute;
    font-size: 32px;
    line-height: 36px;
    border: none;
    overflow: hidden;

    list-style: none outside;
    background-color: #fff;
    z-index: 10000;
    margin: 0;
    padding: 0;

    border-style: solid;
    border-color: #000;
    border-width: 0px 1px 1px 1px;
  }

  .doc-combobox-dropdown li {
    margin: 0;
    padding: 2px 5px;
    font-family: Helvetica, Arial, sans-serif;
  }

  .doc-combobox-dropdown li.selected {
    background-color: #c6c7c8;
  }

  .doc-hide {
    display: none;
  }

  .doc-textbox:focus {
    background-color: transparent;
    border: none;
    outline: none;
  }

  .doc-required {
    border:2px groove red;
  }

  .doc-checkbox {
    display: block;
    position: absolute;
    overflow: hidden;
    background-color: rgba(221, 228, 255, 0.5);
    border: 1px solid #949599;
  }

  .doc-checkbox > input {
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .doc-checkbox.doc-checked {
    background: url(/img/forms/check.png) no-repeat center;
  }

  .doc-strikebox.doc-checked {
    background-color: #000;
  }

  .doc-strikebox {
    display: block;
    position: absolute;
    margin-top: 5px;
    border: 0;
    background-color: rgba(218, 1, 1, 0.2);
    cursor: pointer;
  }

  .doc-readonly {
    background-color: transparent;
    -webkit-text-fill-color:#000;
    color: #000;
  }

  .print-documentpane .doc-strikebox, .print-documentpane .doc-checkbox {
    background-color: transparent;
  }

  .print-documentpane .doc-strikebox.doc-checked {
    background-color: #000;
  }

  .doc-strikebox > input {
    display: none;
  }

  .doc-strikebox:checked {
    background-image: none;
    background-color: rgba(221, 228, 255, 0.5);
    border: 1px solid #949599;
    border: none;
  }

  .doc-checkbox-print:checked {
    background-image: none;
    background-color: transparent;
    border: none;
  }

  .fulljustify {
    text-align: justify !important;
    white-space: normal !important;
    text-justify: distribute;
  }

  .fulljustify:after {
    content: "";
    display: inline-block !important;
    width: 100%;
  }
}
`;