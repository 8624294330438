import { useState } from 'react';
import config from '../config';

const useUtilities = ({ signerId, envelopeId, providerId }) => {
  const [ url, setUrl ] = useState(null);
  const [ error, setError ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ complete, setComplete ] = useState(false);
  const [ termsAccepted, setTermsAccepted ] = useState(false);

  const handleSubmit = async (accepted, values = {}) => {
    setLoading(true);

    const submit =  JSON.stringify({
      signer_id: signerId,
      envelope_id: envelopeId,
      terms_accepted: accepted,
      ...values
    })

    try {
      const res = await fetch(
        config.api + '/services/' + providerId,
        {
          body: submit,
          method: 'POST',
          headers: { 'Content-Type': 'application/json'  }
        }
      );

      const body = await res.json();

      setLoading(false);

      if (!res.ok) {
        return setError(true)
      }

      setComplete(true);
      setTermsAccepted(accepted);

      if (body.data && body.data.url) {
        setUrl(body.data.url);
        window.location.href = body.data.url;          
      }
      
    } catch (e) {
      setLoading(false);
      setError(true)
    }
  }

  const status = {
    url,
    loading,
    complete,
    termsAccepted
  };

  return [ handleSubmit, status, error ];
};

export default useUtilities;