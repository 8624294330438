import './index.css';
import './tailwind.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

import React from 'react';
import ReactDOM from 'react-dom';

import config from './config';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from './App';
import * as serviceWorker from './lib/serviceWorker';
import { MOCK_ENABLED } from './constants';
import ThemeProvider from './context/theme';
import HelmetProvider from './context/helmet';

if (MOCK_ENABLED) {
  require('./lib/fetch-mock');
}

Sentry.init({
  enabled: (config.env !== 'development'),
  environment: process.env.REACT_APP_ENV || 'development',
  dsn: "https://e3c6a32d894041fab87a92c8087a4778@sentry.formslive.com.au/5",
  integrations: [new BrowserTracing()],
  release: `onboard@v${process.env.APP_VERSION}`,
  tracesSampleRate: 1.0,
  ignoreErrors: ['__gCrWeb']
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
