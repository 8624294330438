import React from 'react';

const UserDetails = ({ errors, values, onChange, styles }) => {
  const handleChange = (e) => 
    onChange(e.target.name, e.target.value);

  const inputClass = (name) =>
    [ styles.input, errors[name] ? styles.error : '' ].join(' ')

  return (
    <div className="w-full">
      <div>
        <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">First Name</label>
        <div className="mt-1">
          <input
            type="text"
            id="first_name"
            name="first_name"
            placeholder="John"
            onChange={handleChange} 
            value={values.first_name}
            className={inputClass('first_name')}
          />
        </div>
          {!!errors.first_name && <p className="mt-2 text-sm text-red-600" id="first_name-error">{errors.first_name}</p>}
      </div>
      <div>
        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700 mt-2">Last Name</label>
        <div className="mt-1">
          <input 
            type="text"
            id="last_name"
            name="last_name"
            placeholder="Smith"
            onChange={handleChange}
            value={values.last_name}
            className={inputClass('last_name')} 
          />
        </div>
          {!!errors.last_name && <p className="mt-2 text-sm text-red-600" id="last_name-error">{errors.last_name}</p>}
      </div>
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mt-2">Email</label>
        <div className="mt-1">
          <input 
            id="email"
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            className={inputClass('email')} 
            placeholder="you@example.com" />
        </div>
          {!!errors.email && <p className="mt-2 text-sm text-red-600" id="email-error">{errors.email}</p>}
      </div>
      <div>
        <label htmlFor="contact" className="block text-sm font-medium text-gray-700 mt-2">Contact</label>
        <div className="mt-1">
          <input 
            type="tel"
            id="contact"
            name="contact"
            value={values.contact} 
            onChange={handleChange} 
            placeholder="0400000000" 
            className={inputClass('contact')} 
          />
        </div>
          {!!errors.contact && <p className="mt-2 text-sm text-red-600" id="contact-error">{errors.contact}</p>}
      </div>
    </div>
  )
};

UserDetails.defaultProps = {
  errors: {},
  onChange: () => null,
  values: { first_name: '', last_name: '', email: '', contact: '' },
  styles: {
    error: 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500',
    input: 'py-2 px-3 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-200 rounded-md'
  }
};

export default UserDetails;