import React from 'react';
import ErrorSvg from '../images/server_down.svg';

function Error({ message }) {
  return (
    <>
      <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
        <div className="text-center">
          <h2 className="text-2xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-4xl sm:leading-none md:text-4xl">
            An error occurred
          </h2>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            { message }
          </p>          
        </div>
        <div>
            <img className="w-48 mx-auto mt-8" src={ErrorSvg} alt="Error" />
          </div>      
      </div>
    </>
  );
}

export default Error;
