import React, { useState } from 'react';

import UserDetails from '../components/user-details';

const useUserDetails = (data = {}) => {
  const [ errors, setErrors ] = useState({});

  const [ values, setValues ] = useState({ 
    email: data.email || '', 
    contact: data.contact || '',
    last_name: data.last_name || '',
    first_name: data.first_name || ''
  });

  const clearErrors = () => setErrors({});

  const onChangeHandler = (name, value) => 
    setValues(s => ({ ...s, [name]: value }));

  //TODO add additional validation
  const validateFields = () => {
    const required = (key, display) => 
      !values[key] && `You must provide a ${display}.`;

    const fieldErrors = {
      first_name: required('first_name', 'First Name'),
      last_name: required('last_name', 'Last Name'),
      email: required('email', 'Email Address'),
      contact: required('contact', 'Contact Number')
    };

    const isValid = !Object
      .keys(fieldErrors)
      .filter(key => !!fieldErrors[key])
      .length;

    return [ isValid, !isValid && fieldErrors ]
  }

  const validate = () => {
    clearErrors();

    const [ isValid, fieldErrors ] = validateFields();

    if (!isValid) {
      setErrors(fieldErrors);
    }

    return isValid;
  }

  const renderDetails = () =>
    <UserDetails
      errors={errors}
      values={values}
      onChange={onChangeHandler}
    />;

  return [ values, validate, renderDetails ]
};

export default useUserDetails;
