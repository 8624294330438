import React, { useState } from 'react';
import Logo from '../images/your_porter.png';
import Utilities from '../containers/utilities';

import useUserDetails from '../hooks/useUserDetails';

function GetConsent({ onYesClick, onNoClick }) {
  const [agree, setAgree] = useState(null);
  const [hasClicked, setClicked ] = useState(false);

  function onAgree () {
    setAgree(!agree);
  }

  function onClick () {
    if (agree) {
      onYesClick();
    } else {
      setClicked(true);
    }
  }

  return (
  <>
    <p className="mt-4 text-center text-base leading-6 text-blue-600 font-semibold tracking-wide uppercase">Connect Utilities</p>
      <h3 className="text-center mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
      Tailored connections...<br/>
      At your service!
      </h3>
      <div className="px-6 py-2 mt-2 text-base leading-6 text-gray-500 text-center">
        <p className="pb-2">
        <strong>YourPorter</strong> was created solely to save you the time and hassle of arranging your utility connections.
        </p><p className="pb-2">
          We specialise in moving home connections – <strong>that’s all we do!</strong>
          </p><p className="pb-2">
          <strong>So sit back, relax</strong> and leave your utility connections to YourPorter.
          </p><p>
          No tip required – <strong>our service is completely free.</strong>
        </p>      
      </div>
      <div className="mb-4 flex flex-col items-center">
        <img className="px-8 py-4" src={Logo} alt="Your Porter" />
      </div>
      <div className="px-6 pt-2 sm:col-span-2">
        <div className="w-64 flex items-start mx-auto">
          <div className="flex-shrink-0">
            <input id="accept_terms" type="checkbox" className="form-checkbox h-4 w-4 text-yourporter transition duration-150 ease-in-out" onChange={onAgree} checked={agree} />            
          </div>
          <div className="ml-3">
            <p className="text-sm leading-6 text-gray-500">
              I agree to the&nbsp;
              <a href="https://www.yourporter.com.au/general/terms/" rel="noopener noreferrer" target="_blank" className="font-medium text-gray-700 underline">Terms & Conditions</a> and&nbsp;
              <a href="https://www.yourporter.com.au/general/privacy-policy/" rel="noopener noreferrer" target="_blank" className="font-medium text-gray-700 underline">Privacy Policy</a>.
            </p>
            { !agree && hasClicked ? (<p className="text-xs text-red-500 font-semibold">You must agree to the above conditions before proceeding.</p>) : null }
          </div>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto py-4 px-4 flex items-center justify-center flex flex-col">
        <div className="w-64 inline-flex rounded-md shadow">
          <button onClick={onClick} className="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-800 bg-yourporter hover:bg-black hover:text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
            Yes please
          </button>
        </div>
        <div className="w-64 mt-4 inline-flex rounded-md shadow">
          <button onClick={onNoClick} className="w-full inline-flex items-center justify-center px-5 py-3 border border-yourporter text-base leading-6 font-medium rounded-md text-gray-800 hover:border-green-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
            No thanks
          </button>
        </div>
      </div>      
    </>
  );
}

function GetUserDetails({ onSubmit, data = {} }) {
  const [ values, validate, renderDetails ] = useUserDetails(data);

  const handleSubmit = () => {
    if (!validate()) return false;
    else onSubmit(values);
  }

  return (
    <div className="w-full">
      {renderDetails()}
      
      <div id="actions" className="flex items-center justify-center flex flex-col mt-6">
        <div className="w-64 inline-flex rounded-md shadow">
          <button onClick={handleSubmit} className="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-800 bg-yourporter hover:bg-black hover:text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
            Get Connected!
          </button>
        </div>
      </div>
    </div>
  )
}

const Container = ({ children }) => {
  return (
    <>
      <p className="mt-4 text-center text-base leading-6 text-blue-600 font-semibold tracking-wide uppercase">Connect Utilities</p>
      <h3 className="text-center mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
      Tailored connections...<br/>
      At your service!
      </h3>
      <div className="px-6 py-2 mt-2 text-base leading-6 text-gray-500 text-center">
        <p className="pb-2">
        <strong>YourPorter</strong> was created solely to save you the time and hassle of arranging your utility connections.
        </p><p className="pb-2">
We specialise in moving home connections – <strong>that’s all we do!</strong>
</p><p className="pb-2">
<strong>So sit back, relax</strong> and leave your utility connections to YourPorter.
</p><p>
No tip required – <strong>our service is completely free.</strong>
        </p>      
      </div>
      <div className="mb-4 flex flex-col items-center">
        <img className="px-8 py-4" src={Logo} alt="Your Porter" />
      </div>
      <div className="
        flex items-center flex-col w-full px-6
        md:w-3/4 md:mx-auto
        lg:w-2/4 lg:mt-6
        xl:w-2/6
      ">
        {children}
      </div>
    </>
  )
}

const Details = (props) => <Container><GetUserDetails {...props} /></Container>

function YourPorter({ platform, data, envelopeId, signerId }) {
  return (
    <Utilities 
      data={data}
      Intro={GetConsent}
      platform={platform}
      signerId={signerId}
      Details={Details}
      envelopeId={envelopeId}
      providerId={'your-porter'}
    />
  );
}

export default YourPorter;
