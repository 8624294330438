import fetchMock from 'fetch-mock';
import { MOCK_USER_DETAILS, DEFAULT_PROVIDER } from '../constants';
/*
  mmi
  foxie
  home-now
  dc
  movinghub
  myconnect
  prd-connet
  your-porter
  whole-house
  best-connect
  compare-connect
  default
*/

fetchMock
  .get(
    'http://localhost:3001/onboard/0000-0000-0000-0000-0000-0000-0001/test_signer', 
    { details: MOCK_USER_DETAILS, provider: DEFAULT_PROVIDER }, 
    { delay: 2000 }
  )
  .post('http://localhost:3001/check-in/1', 201, { delay: 2000 })
  .post('http://localhost:3001/contact-us/reso', 201, { delay: 2000 })
  .post(`http://localhost:3001/services/${DEFAULT_PROVIDER}`, {}, { delay: 2000 })

export default fetchMock;