import queryString from '../lib/query-string';

import { MOCK_QUERY_STRING } from '../constants';

const useQueryParams = (isDev) => {
  if (isDev) {
    return { ...MOCK_QUERY_STRING }
  }

  return {
    formId: queryString('form_id'),
    requestId: queryString('request_id'),
    signerId: queryString('signer_id'),
    envelopeId: queryString('envelope_id'),
    platform: queryString('platform') || 'vic',
    status: queryString('status') || 'default',

    firstName: queryString('first_name'),
    lastName: queryString('last_name'),
    email: queryString('email'),
    phone: queryString('phone')
  }
};


export default useQueryParams;