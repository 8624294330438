import React from 'react';

import {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  useLayoutEffect
} from 'react';

import THEMES from '../themes';
import config from '../config';
import objectPath from 'object-path';

const ThemeStateContext = createContext();
const ThemeDispatchContext = createContext();

export const useThemeState = () => useContext(ThemeStateContext);

export const useThemeDispatch = () => useContext(ThemeDispatchContext);

export const useApplyTheme = (data, path, loading) => {
  const { isAgencyTheme, loading: themeLoading } = useThemeState();
  const { setTheme, setLoading: setThemeLoading } = useThemeDispatch();

  useEffect(() => {
    if (isAgencyTheme) return;

    const theme = objectPath.get(data, path);

    if (theme) {
      setTheme(theme);
    } else if (!loading && themeLoading) {
      setThemeLoading(false)
    }
  }, [
    data,
    path,
    loading,
    setTheme,
    themeLoading,
    isAgencyTheme,
    setThemeLoading
  ])
}

export const defaultTheme = THEMES[config.theme || 'BASE'];

export default function ThemeProvider(props) {
  const [loading, setLoading] = useState(true);
  const [isAgencyTheme, setAgencyTheme] = useState(false);
  const [state, setState] = useState(defaultTheme);
  
  const applyTheme = useCallback((config) => {
    const root = document.documentElement;
    const mappedTheme = mapTheme(config);

    function mapTheme (config = {}) {
      return {
        '--color-primary': config.primary || '',
        '--background-primary': config.primary || '',

        '--color-secondary': config.secondary || '',
        '--background-sec': config.secondary || '',

        '--background-nav': config['nav-background'] || config.primary || '',

        '--background-primary-btn': config['primary-btn-background'] || config.primary || '',
        '--color-primary-btn': config['primary-btn-text'] || '#ffffff'
      };
    };

    Object.keys(mappedTheme).forEach((key) => {
      root.style.setProperty(key, mappedTheme[key]);
    });
  }, []);

  function setTheme (theme) {
    setState(theme);
    setLoading(false);
    setAgencyTheme(true);
  }

  useLayoutEffect(() => {
    applyTheme(state.colors)
  }, [ applyTheme, state ])

  return (
    <ThemeStateContext.Provider value={{ ...state, loading, isAgencyTheme }}>
      <ThemeDispatchContext.Provider
        value={{
          setLoading,
          setTheme
        }}
      >
        {props.children}
      </ThemeDispatchContext.Provider>
    </ThemeStateContext.Provider>
  );
};

