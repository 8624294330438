import React from 'react';

function Policy({ onClose }) {
  return (
    <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">      
        <div className="my-4 border-t border-gray-200 pt-5">
          <div className="flex justify-end">
            <span className="sm:max-w-sm w-full inline-flex rounded-md shadow-sm">
              <button onClick={onClose} className="sm:max-w-sm w-full inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
                Close
              </button>
            </span>
          </div>
        </div>
      <article className="prose">
      <h1>Privacy Policy</h1>
<p>Dynamic Methods and its related companies (referred to in this document as we, us or our) recognise that your privacy is very important and we are committed to protecting the personal information we collect from you. The Privacy Act 1988 (Cth) (Privacy Act) and Australian Privacy Principles (APPs) govern the way in which we must manage your personal information. This policy sets out how we collect, use, disclose and otherwise manage personal information about you.</p>
<h2>Definitions</h2>

<p>Personal Information means information or an opinion about an identified individual, or an individual who is reasonably identifiable:</p>
  <ul>
    <li>whether the information or opinion is true or not; and</li>
    <li>whether the information or opinion is recorded in a material. </li>
  </ul>
<h2>Types of information collected</h2>

<p>We only collect such information as is necessary to conduct business with you. We may collect and hold personal information about you, that is, information that can identify you, and is relevant to providing you with the services you are seeking. The kinds of information we typically collect include name, gender, occupation, position, address, contact details like phone numbers and email addresses, and electronic information from your use of our website (see further below). If you are a real estate agent, we may ask for your licence number.
Method of collection</p>

<p>Personal information will generally be collected directly from you through:</p>

    <ul><li>the use of any of our standard forms, over the internet, via email, through a telephone conversation with you; or</li>
    <li>your use of any of our software, social media platforms, online services (including any software-as-a-service or other online portal or platform that we operate) or mobile apps. </li>
    </ul>

<p>We may collect personal information indirectly about you from real estate agents and agencies, or other third parties because it is unreasonable or impractical to collect such personal information directly from you. We will usually notify you about these instances in advance, or where that is not possible, as soon as reasonably practicable after the information has been collected. We may also rely on those third parties to inform you that the information is being collected and provided to us.
Purpose of collection</p>

<p>The personal information that we collect and hold about you depends on your interaction with us. We use personal information for the primary purpose for which it was collected, or for secondary purposes which are related to the primary purpose if an exception from the APPs applies. Generally, we will collect, use and hold your personal information and business information if it is reasonably necessary for or directly related to the performance of our functions and for the purposes of:</p>

<ul><li>recording registration information for each product or service you purchase;</li>
<li>providing products and services to you or someone else you know;</li>
<li>provide better customer service to you;</li>
<li>monitoring, auditing and evaluating our products and services;</li>
<li>performing authorised financial transactions with you and your associated financial institutions;</li>
<li>providing you with marketing information about other services that we, our related entities and other organisations that we have affiliations with, offer that may be of interest to you;</li>
<li>facilitating our internal business operations (including modelling data, maintaining our relationship with you, data testing and security);</li>
<li>analysing our products and services and customer needs with a view to developing new or improved products and services;</li>
<li>complying with any legal or regulatory requirements; and</li>
<li>dealing with any complaints or enquiries. </li>
</ul>

<p>Aggregated data that contains no information specific to a particular person or business may be shared with our business partners, for example, aggregated statistical trends in a particular industry sector.</p>
<h2>Failure to provide information</h2>

<p>If the personal information you provide to us is incomplete or inaccurate, we may be unable to provide you, or someone else you know, with the products or services you, or they, are seeking.</p>
<h2>Use and Disclosure</h2>

<p>Generally, we only use or disclose personal information about you for the purposes for which it was collected (as set out above), but also;</p>

<ul><li>in connection with order fulfilment, product registration and customer service activities;</li>
    <li>as required to exercise or enforce any legal or contractual right we may have against you or any other person; and</li>
    <li>to ensure secure access to Dynamic Method's websites, products and services. </li>
    </ul>
    <p>We may also disclose personal information about you to:</p>

    <ul><li>our related entities to facilitate our and their internal business processes;</li>
    <li>third-party service providers, who assist us in operating our business (including professional advisors, financial advisers and dealers, and technology service providers), and we will where possible require these service providers to comply with our privacy policy but you acknowledge that compliance may not always be possible;</li>
    <li>our related entities and other organisations with whom we have affiliations so that those organisations may provide you with information about services and various promotions;</li>
    <li>third parties who may then contact you for advertising and direct marketing purposes; and</li>
    <li>any advertisers of goods or services on the Dynamic Methods website or within our products and services (if any). </li>
    </ul>
    <p>In some circumstances, the law may permit or require us to use or disclose personal information for other purposes (for instance where you would reasonably expect us to and the purpose is related to the purpose of collection).</p>
<h2>Direct marketing</h2>

<p>Dynamic Methods needs to be able to contact you about product upgrades and new product releases and to inform you of new services relevant to your business, including special offers. You may, however, notify us at any time that you do not want to receive any marketing communications from Dynamic Methods using the method of opting out which is shown in the communication.</p>
<h2>Is my data secure?</h2>

<p>The security of your personal information is important to us. We take all reasonable measures to ensure that your personal information is stored safely to protect it from misuse, loss, unauthorised access, modification or disclosure, including electronic and physical security measures.</p>

<p>Dynamic Methods web pages that request certain information or allow transaction processing use the Hypertext Transport Protocol Security (HTTPS) protocol, which allows data to be transmitted in an encrypted form known as Secure Sockets Layer® (SSL). Dynamic Methods encrypts, with 128-bit SSL, all such information. We generally use such information for the purpose of completing your request, but we may also use it for marketing purposes. Although the site itself does not store this information (via cookie or otherwise), the information is eventually transferred to a customer management database not directly linked to our website systems.</p>

<p>You can confirm that a particular Dynamic Methods page is secure by checking that:</p>

    <ul><li>the page address in the web browser’s toolbar or status bar begins with “https://”; or</li>
    <li>the padlock icon in the web browser’s toolbar or status bar is locked. </li></ul>

    <p>Unfortunately, no data transmission over the internet can be guaranteed as totally secure. Whilst Dynamic Methods strives to protect your personal information, we cannot guarantee and cannot ensure complete security of any information that you transmit or provide to us through the internet (including through any mobile app or online service). Accordingly, any personal information or other information that you transmit or provide to us is provided at your own risk.</p>
<h2>Destruction and de-identification</h2>

<p>We will take reasonable steps to destroy or de-identify personal information if it is no longer needed for any purpose for which it may be used or disclosed.</p>
<h2>Where is it stored?</h2>

<p>Client data is stored on servers hosted by Dynamic Methods (or its related entities) or a third party hosting provider.</p>

<h2>Internet users</h2>

<p>If you access our website, we may collect additional personal information about you in the form of your IP address and domain name.</p>

<p>We also use Internet Protocol (IP) addresses to analyse trends, administer our websites, track your navigation among Dynamic Methods web pages and gather broad information for aggregate use. For each visitor to our site, our web server automatically attempts to collect general, non-personally identifiable information. Examples of this type of information include the visitor’s domain name, which pages are visited, and from which site the visitor came.</p>

<p>Our website may use cookies from time to time. The main purpose of cookies is to identify users and to prepare customised web pages for them. Our web servers may also send cookies to your browser to track navigation history and ensure transaction pages, such as shopping cart interactions, work correctly. Cookies contain only coded information that cannot be used by third parties to access your confidential information. Cookies do not identify you personally, but they may link back to a database record about you. We use cookies to monitor usage of our website and to create a personal record of when you visit our website and what pages you view so that we may serve you more effectively.</p>

<p>Our website may contain links to other websites. We are not responsible for the privacy practices of linked websites and linked websites are not subject to our privacy policies and procedures.</p>
<h2>Accountability and legislative compliance</h2>

<p>We inform our employees about the importance of confidentiality and customer privacy through our operating procedures, training programs and internal policies. We will take appropriate disciplinary measures to enforce privacy responsibilities of its employees. If we contract with external service providers to provide service support, Dynamic Methods will endeavour to require them to conform to its privacy policy.</p>
<h2>Access and correction</h2>

<p>You may access the personal information we hold about you by making a written request. We will respond to your request within a reasonable period. We may charge you a reasonable fee for providing access to your personal information (but not for making a request for access).</p>

<p>We may decline a request for access to personal information in circumstances prescribed by the Privacy Act, and if we do, we will provide you with a written notice that sets out the reasons for the refusal (unless it would be unreasonable to provide those reasons).</p>

<p>If, upon receiving access to your personal information or at any other time, you believe the personal information we hold about you is inaccurate, incomplete or out of date, please notify us immediately. We will take reasonable steps to correct the information so that it is accurate, complete and up to date.</p>

<p>If we refuse to correct your personal information, we will provide you with a written notice that sets out the reasons for our refusal (unless it would be unreasonable to provide those reasons) and provide you with a statement regarding the mechanisms available to you to make a complaint.</p>
<h2>Complaints and Feedback</h2>

<p>If you wish to make a complaint about a breach of the Privacy Act, APPs or a privacy code that applies to us, please contact us as set out below and we will take reasonable steps to investigate the complaint and respond to you. If you are not happy with our response, you may complain directly to the Australian Information Commissioner.</p>

<p>If you have any queries or concerns about our privacy policy or the way we handle your personal information or need to update or correct your personal or business information, please contact our privacy officer at: privacy@dynamicmethods.com.au</p>

<p>Our Privacy Officer will respond within 30 days to your enquiry or complaint. If you make a complaint to our Privacy Officer but you are not satisfied with the response that you receive, you can telephone the Information Commissioner’s hotline on 1300 363 992 or make a complaint via their website – http://www.oaic.gov.au/about-us/contact-us.
More information</p>

<p>For more information about privacy in general, you can visit the federal Information Commissioner’s website at www.oaic.gov.au.</p>
<h2>Changes to this Privacy Policy</h2>

<p>Any changes to this Privacy Policy that may be made from time to time will be available on the Dynamic Methods website. You can also obtain a copy of the current Privacy Policy by either emailing or writing to the Dynamic Methods Privacy Officer.</p>

      </article>
      <div className="mt-4 border-t border-gray-200 pt-5">
          <div className="flex justify-end">
            <span className="sm:max-w-sm w-full inline-flex rounded-md shadow-sm">
              <button onClick={onClose} className="sm:max-w-sm w-full inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
                Close
              </button>
            </span>
          </div>
        </div>
    </div>
  )
}

export default Policy;
