import configs from '../configs';

const getConfig = (env, platform) => {
  if (configs[env] && configs[env][platform]) {
    return configs[env][platform];
  }

  return configs[env]['vic'];
};

export default getConfig;