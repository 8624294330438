import { useState, useEffect } from 'react';
import { useApplyTheme } from '../context/theme';

import config from '../config';

const useOnbaord = (signerId, envelopeId, status, showErrors = false) => {
  const [loading, setLoading] = useState(true);
  const [onboard, setOnboard] = useState(null);
  const [error, setError] = useState(null);

  useApplyTheme(onboard, 'theme', loading);

  useEffect(() => {
    const validate = () => {
      if (showErrors && (!status || !envelopeId)) {
        setLoading(false);

        if (!status) setError("`status` is not defined.");
        else if (!envelopeId) setError("`envelope_id` is not defined.");

        return false;
      }

      if (!signerId || status !== 'complete') {
        setOnboard({ provider: 'default' });
        setLoading(false);

        return false
      }

      return true;
    }

    const getData = async () => {
      try {
        const response = await fetch(
          `${config.api}/onboard/${envelopeId}/${signerId}`,
          {  
            method: 'GET', 
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
          }
        )

        const data = await response.json();

        if (!data.error) {
          setOnboard(data);
        } else {
          showErrors 
            ? setError(data.error) 
            : setOnboard({ provider: 'default' });
        }

        setLoading(false);
      } catch (e) {
        console.log('fetch onboard details error', e);
        setLoading(false);

        showErrors 
          ? setError(e) 
          : setOnboard({ provider: 'default' });
      }
    }

    if (validate()) {
      getData();
    }
  }, [ 
    status,
    signerId,
    envelopeId,
    showErrors
  ]);

  return [ loading, onboard, error ]
}

export default useOnbaord;