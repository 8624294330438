import React from 'react';

import RelaxSvg from '../images/relax.svg';

function Positive() {
  return (
    <>
      <p className="mt-4 text-center text-base leading-6 text-blue-600 font-semibold tracking-wide uppercase">Connect Utilities</p>
      <h3 className="text-center mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Sit back and relax
      </h3>
      <p className="px-6 py-2 mt-2 text-base leading-6 text-gray-500 text-center">
        We will contact you soon via email or phone.
      </p>
      <div>
        <img className="w-48 mx-auto mt-8" src={RelaxSvg} alt="Relax" />
      </div>
    </>
  );
}

export default Positive;
