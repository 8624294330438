import React, { useState } from 'react';
import Logo from '../images/compare_connect.svg';
import Utilities from '../containers/utilities';
import UtilsImg from '../images/utilities.svg';

import useUserDetails from '../hooks/useUserDetails';

function GetConsent({ onYesClick, onNoClick }) {
  const [agree, setAgree] = useState(null);
  const [hasClicked, setClicked ] = useState(false);

  function onAgree () {
    setAgree(!agree);
  }

  function onClick () {
    if (agree) {
      onYesClick();
    } else {
      setClicked(true);
    }
  }

  return (
  <>
      <h3 className="mt-6 mx-4 text-center text-3xl leading-8 font-semibold tracking-tight text-gray-800 sm:text-4xl sm:leading-10">
      Don't forget to connect your utilities.
      </h3>
      <div className="flex flex-col items-center">
        <img className="px-2 my-4 sm:px-8 max-w-xs sm:max-w-md" src={Logo} alt="The Whole House" />
      </div>
      <div className="px-4 pb-2 text-xl font-semibold leading-6 text-gray-900 text-center">
        <p>
          One click away from arranging all your moving &amp; utilty services.
        </p>      
      </div>      
      <div className="mb-2 flex flex-col items-center">
        <img className="px-0 py-4 max-w-xs sm:max-w-lg" src={UtilsImg} alt="Electricity, Gas, Broadband, PayTV, Movers" />
      </div>
      <div className="px-6 pt-2 sm:col-span-2">
        <div className="w-64 flex items-start mx-auto">
          <div className="flex-shrink-0">
            <input id="accept_terms" type="checkbox" className="form-checkbox h-4 w-4 text-resiconnect transition duration-150 ease-in-out" onChange={onAgree} checked={agree} />            
          </div>
          <div className="ml-3">
            <p className="text-sm leading-6 text-gray-500">
              I agree to the&nbsp;
              <a href="https://snlwholehouse.resiconnect.com.au/terms-and-conditions" rel="noopener noreferrer" target="_blank" className="font-medium text-gray-700 underline">Terms & Conditions</a> and&nbsp;
              <a href="https://snlwholehouse.resiconnect.com.au/privacy-policy" rel="noopener noreferrer" target="_blank" className="font-medium text-gray-700 underline">Privacy Policy</a>.
            </p>
            { !agree && hasClicked ? (<p className="text-xs text-red-500 font-semibold">You must agree to the above conditions before proceeding.</p>) : null }
          </div>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto py-4 px-4 flex items-center justify-center flex flex-col">
        <div className="w-64 inline-flex rounded-md shadow">
          <button onClick={onClick} className="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-semibold rounded-md text-white bg-resiconnect hover:bg-black hover:text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
            Yes please
          </button>
        </div>
        <div className="w-64 mt-4 inline-flex rounded-md shadow">
          <button onClick={onNoClick} className="w-full inline-flex items-center justify-center px-5 py-3 border border-resiconnect text-base leading-6 font-medium rounded-md text-gray-800 hover:border-green-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
            No thanks
          </button>
        </div>
      </div>      
    </>
  );
}

function GetUserDetails({ onSubmit, data = {} }) {
  const [ values, validate, renderDetails ] = useUserDetails(data);

  const handleSubmit = () => {
    if (!validate()) return false;
    else onSubmit(values);
  }

  return (
    <div className="w-full">
      {renderDetails()}
      
      <div id="actions" className="flex items-center justify-center flex flex-col mt-6">
        <div className="w-64 inline-flex rounded-md shadow">
          <button onClick={handleSubmit} className="w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-semibold rounded-md text-white bg-resiconnect hover:bg-black hover:text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
            Get Connected!
          </button>
        </div>
      </div>
    </div>
  )
}

const Container = ({ children }) => {
  return (
    <>
      <h3 className="mt-6 mx-4 text-center text-3xl leading-8 font-semibold tracking-tight text-gray-800 sm:text-4xl sm:leading-10">
      Don't forget to connect your utilities.
      </h3>
      <div className="flex flex-col items-center">
        <img className="px-2 my-4 sm:px-8 max-w-xs sm:max-w-md" src={Logo} alt="The Whole House" />
      </div>
      <div className="px-4 pb-2 text-xl font-semibold leading-6 text-gray-900 text-center">
        <p>
          One click away from arranging all your moving &amp; utilty services.
        </p>      
      </div>      
      <div className="mb-2 flex flex-col items-center">
        <img className="px-0 py-4 max-w-xs sm:max-w-lg" src={UtilsImg} alt="Electricity, Gas, Broadband, PayTV, Movers" />
      </div>
      <div className="
        flex items-center flex-col w-full px-6
        md:w-3/4 md:mx-auto
        lg:w-2/4 lg:mt-6
        xl:w-2/6
      ">
        {children}
      </div>
    </>
  )
}

const Details = (props) => <Container><GetUserDetails {...props} /></Container>

function WholeHouse({ platform, data, envelopeId, signerId }) {
  return (
    <Utilities 
      data={data}
      Intro={GetConsent}
      platform={platform}
      signerId={signerId}
      Details={Details}
      envelopeId={envelopeId}
      providerId={'whole-house'}
    />
  );
}

export default WholeHouse;
